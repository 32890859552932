import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import { Formik, Form, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import FullWidthGrid from '../../components/grid';
// import { addUserAccount } from "../../utilities/validations";
import { TesozApi } from "../../api";

import "./index.scss";
import SimpleAlerts from "../../components/alerts";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
// import {countriesList} from "../../utilities/constants";

const t_prefix = "add_new_user."

const AddNewUserContent = () => {
    const [error, setError] = useState('');
    const history = useHistory()
    const {t} = useTranslation()
    const api = new TesozApi()
    // const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    const addUserAccount = Yup.object().shape({
        login: Yup.string()
            .required(t(t_prefix+'required'))
            .min(2, t(t_prefix+'login_length_short'))
            .max(50, t(t_prefix+'login_length_long')),
        email: Yup.string().email(t(t_prefix+'invalid_email')).required(t(t_prefix+'required')),
        signatureLimit: Yup.number()
            .positive()
            .integer(),
        // .min(1, "Invalid.")
        // .max(31, "Invalid."),
        // useKeysInConfig: Yup.string()
        //     .required('Required *')
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        // tesozPublicKey: Yup.string()
        //   .required('Required *')
        //   .min(2, 'Too Short!')
        //   .max(50, 'Too Long!'),
        // tesozPrivateKey: Yup.string()
        //   .required('Required *')
        //   .min(2, 'Too Short!')
        //   .max(50, 'Too Long!'),
        fullName: Yup.string()
            .required(t(t_prefix+'required'))
            .min(2, t(t_prefix+'full_name_length_short'))
            .max(50, t(t_prefix+'full_name_length_long')),
        // password: Yup.string().required('Required *'),
        // passwordConfirmation: Yup.string().required('Required *').oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const handleSubmit = (values, resetForm) => {
        console.log("values new user", values)
        let newValues = {...values}
        // debugger
        if(newValues.signatureLimit === ""){
            delete newValues["signatureLimit"]
        }

        if(newValues.company === ""){
            delete newValues["company"]
        }

        if(newValues.country === ""){
            delete newValues["country"]
        }

        if(newValues.isTezosAccountInConfig){
            delete newValues["privateKey"]
            delete newValues["publicKey"]
        }

        if(newValues.privateKey === ""){
            delete newValues["privateKey"]
        }

        if(newValues.publicKey === ""){
            delete newValues["publicKey"]
        }

        if(newValues.publicKey){
            const tesozAccount = {
                privateKey: newValues.privateKey,
                publicKey: newValues.publicKey
            }
            const updatedValues = {...newValues, tesozAccount}
            newValues = {...updatedValues};

            if(newValues.privateKey){
                delete newValues["privateKey"]
            }

            if(newValues.publicKey){
                delete newValues["publicKey"]
            }
        }

        addUser(newValues, resetForm)
    }

    const addUser = async (data, resetForm) => {
        // await sleep(500);
        // alert(JSON.stringify(data, null, 2));

        api.AddAccount(data).then(response => {
            if(response){
                if(response.status === 200 && response.data){
                    resetForm()
                    history.push("/manageusers")
                }
            }
        }).catch(e => {
            console.log("e", e)
            if(e){
                if(e.response){
                    if(e.response.data){
                        if(e.response.data.message){
                            setError(e.response.data.message)
                        }
                        else {
                            setError("Sorry something went wrong")
                        }
                    }
                    else {
                        setError("Sorry something went wrong")
                    }
                }
                else {
                    setError("Sorry something went wrong")
                }
            }
            else {
                setError("Sorry something went wrong")
            }
        })
    }

    const handleReset = (resetForm) => {
        resetForm()
    }

    return (
        <div className="add-new-user">
            <div className="title">
                Add a User
            </div>

            {error && error.length > 0 && <SimpleAlerts type="error" message={error} />}

            <div>
            <Formik
                        initialValues={{
                            login: '',
                            email: '',
                            company: '',
                            country: '',
                            isAdmin: false,
                            signatureLimit: '',
                            isTezosAccountInConfig: false,
                            privateKey: '',
                            publicKey: '',
                            // tezosAccount: {
                            //     privateKey: '',
                            //     publicKey: ''
                            // },
                            fullName: '',
                            disabled: false
                        }}

                        validationSchema={addUserAccount}

                        onSubmit={(values, {resetForm}) => handleSubmit(values, resetForm)}
                    >
                        {({ values, errors, touched, resetForm }) => (
                            <Form className="edit-account-form">
                                <FullWidthGrid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'login_label')} *</label>
                                        <Field name="login" placeholder={t(t_prefix+'login_placeholder')} className={`edit-acc-text-field ${(!errors.login || !touched.login) ? 'edit-margin-btm' : ''}`} />
                                        {errors.login && touched.login ? (
                                            <div className="mgn-btm">{errors.login}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'email_label')}</label>
                                        <Field name="email" type="email" placeholder={t(t_prefix+'email_placeholder')} className={`edit-acc-text-field ${(!errors.email || !touched.email) ? 'edit-margin-btm' : ''}`} />
                                        {errors.email && touched.email ? <div className="mgn-btm">{errors.email}</div> : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'company_label')}</label>
                                        <Field name="company" placeholder={t(t_prefix+'company_placeholder')} className={`edit-acc-text-field ${(!errors.company || !touched.company) ? 'edit-margin-btm' : ''}`} />
                                        {errors.company && touched.company ? (
                                            <div className="mgn-btm">{errors.company}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'country_label')}</label>
                                        <Field name="country" placeholder={t(t_prefix+'country_placeholder')} className={`edit-acc-text-field ${(!errors.country || !touched.country) ? 'edit-margin-btm' : ''}`} />
                                        {errors.country && touched.country ? (
                                            <div className="mgn-btm">{errors.country}</div>
                                        ) : null}
                                    </Grid>

                                    {/*<Grid item sm={12} lg={6}>*/}
                                    {/*    <label className="edit-acc-label">Country</label>*/}
                                    {/*    <Field name="country" as="select" placeholder="Seelct your Country" className={`select-field edit-acc-text-field ${(!errors.country || !touched.country) ? 'edit-margin-btm' : ''}`} >*/}
                                    {/*        {countriesList.map(country => {*/}
                                    {/*            const {name, code} = country;*/}
                                    {/*            return (*/}
                                    {/*                <option value={code}>{name}</option>*/}
                                    {/*            )*/}
                                    {/*        })}*/}
                                    {/*    </Field>*/}
                                    {/*    {errors.country && touched.country ? (*/}
                                    {/*        <div className="mgn-btm">{errors.country}</div>*/}
                                    {/*    ) : null}*/}
                                    {/*</Grid>*/}


                                    <Grid item sm={12}>
                                        <div>
                                            <label className="edit-acc-label">{t(t_prefix+'admin_label')}</label>
                                        </div>
                                        <Field type="checkbox" name="isAdmin" placeholder="Your Profile" className={`add-checkbox-field ${(!errors.isAdmin || !touched.isAdmin) ? 'edit-margin-btm' : ''}`} />
                                        {errors.isAdmin && touched.isAdmin ? (
                                            <div className="mgn-btm">{errors.isAdmin}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12}>
                                        <label className="edit-acc-label">{t(t_prefix+'sign_limit_label')}</label>
                                        <Field type="number" name="signatureLimit" placeholder={t(t_prefix+'sign_limit_placeholder')} className={`edit-acc-text-field ${(!errors.signatureLimit || !touched.signatureLimit) ? 'edit-margin-btm' : ''}`} />
                                        {errors.signatureLimit && touched.signatureLimit ? (
                                            <div className="mgn-btm">{errors.signatureLimit}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12}>
                                        <div>
                                            <label className="edit-acc-label">Use keys in configuration</label>
                                        </div>
                                        <Field type="checkbox" name="isTezosAccountInConfig" className={`add-checkbox-field ${(!errors.isTezosAccountInConfig || !touched.isTezosAccountInConfig) ? 'edit-margin-btm' : ''}`} />
                                        {errors.isTezosAccountInConfig && touched.isTezosAccountInConfig ? (
                                            <div className="mgn-btm">{errors.isTezosAccountInConfig}</div>
                                        ) : null}
                                    </Grid>



                                    {!values.isTezosAccountInConfig &&
                                    <>
                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'tesoz_public_key_label')}</label>
                                        <Field name="publicKey" placeholder={t(t_prefix+'tesoz_public_key_placeholder')} className={`edit-acc-text-field ${(!errors.publicKey || !touched.publicKey) ? 'edit-margin-btm' : ''}`} />
                                        {errors.publicKey && touched.publicKey ? (
                                            <div className="mgn-btm">{errors.publicKey}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'tesoz_private_key_label')}</label>
                                        <Field name="privateKey" placeholder={t(t_prefix+'tesoz_private_key_placeholder')} className={`edit-acc-text-field ${(!errors.privateKey || !touched.privateKey) ? 'edit-margin-btm' : ''}`} />
                                        {errors.privateKey && touched.privateKey ? (
                                            <div className="mgn-btm">{errors.privateKey}</div>
                                        ) : null}
                                    </Grid>
                                    </>}

                                    {/*<Grid item sm={12} lg={6}>*/}
                                    {/*    <label className="edit-acc-label">Change Password</label>*/}
                                    {/*    <Field name="password" type="password" placeholder="Enter Your Password" className={`edit-acc-text-field ${(!errors.password || !touched.password) ? 'edit-margin-btm' : ''}`} />*/}
                                    {/*    {errors.password && touched.password ? (*/}
                                    {/*        <div className="mgn-btm">{errors.password}</div>*/}
                                    {/*    ) : null}*/}
                                    {/*</Grid>*/}

                                    {/*<Grid item sm={12} lg={6}>*/}
                                    {/*    <label className="edit-acc-label">Confirm Change Password</label>*/}
                                    {/*    <Field name="passwordConfirmation" type="password" placeholder="Enter Your Password" className={`edit-acc-text-field ${(!errors.passwordConfirmation || !touched.passwordConfirmation) ? 'edit-margin-btm' : ''}`} />*/}
                                    {/*    {errors.passwordConfirmation && touched.passwordConfirmation ? (*/}
                                    {/*        <div className="mgn-btm">{errors.passwordConfirmation}</div>*/}
                                    {/*    ) : null}*/}
                                    {/*</Grid>*/}

                                    <Grid item sm={12}>
                                        <label className="edit-acc-label">{t(t_prefix+'full_name_label')} *</label>
                                        <Field name="fullName" placeholder={t(t_prefix+'full_name_placeholder')} className={`edit-acc-text-field ${(!errors.fullName || !touched.fullName) ? 'edit-margin-btm' : ''}`} />
                                        {errors.fullName && touched.fullName ? (
                                            <div className="mgn-btm">{errors.fullName}</div>
                                        ) : null}
                                    </Grid>

                                    {/*<Grid item sm={12} lg={6}>*/}
                                    {/*    <label className="edit-acc-label">Last Name</label>*/}
                                    {/*    <Field name="lastName" placeholder="Enter Last Name" className={`edit-acc-text-field ${(!errors.lastName || !touched.lastName) ? 'edit-margin-btm' : ''}`} />*/}
                                    {/*    {errors.lastName && touched.lastName ? (*/}
                                    {/*        <div className="mgn-btm">{errors.lastName}</div>*/}
                                    {/*    ) : null}*/}
                                    {/*</Grid>*/}

                                </FullWidthGrid>


                                <div className="mandatory-container">
                                    <label>* {t(t_prefix+'fields_are_mandatory_label')}</label>
                                </div>


                                <div className="sign-btns-container" >
                                                <Button onClick={() => handleReset(resetForm)} className="btn btn-secondary-red btn-lg fifty-percent-rounded signature-btns signature-margin-right" disabled={!values.login && !values.email && !values.fullName}>
                                                    {t(t_prefix+'cancel_btn')}
                                                </Button>
                                                <Button className="btn btn-secondary btn-lg fifty-percent-rounded signature-btns" type="submit" disabled={!values.login || !values.email || !values.fullName || (values.privateKey.length > 0 && values.publicKey.length < 1) || (values.publicKey.length > 0 && values.privateKey.length < 1)}>
                                                    {t(t_prefix+'save_btn')}
                                                </Button>
                                </div>


                            </Form>
                        )}
                    </Formik>
            </div>

        </div>
    )
}

export default AddNewUserContent
