import React, {useState} from 'react';
import InfoIcon from '@material-ui/icons/Info';
import "./index.scss";
import {useTranslation} from "react-i18next";

const t_prefix = "jobs."

const CustomToolTip = ({type, id}) => {
    const [showId, setShowId] = useState(false)
    const {t} = useTranslation();

    const handleClipboard = (text) => {
        navigator.clipboard.writeText(text).then(function() {
            console.log('Async: Copying to clipboard was successful!');
            setShowId(false)
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    return (
        <>
            <InfoIcon className="tooltip-custom-icon" onClick={() => setShowId(id => !id)}/>
            <div className={`custom-toolTip ${showId ? "show-custom-toolTip" : "hide-custom-toolTip"}`}>
                {type}: <label id="myId" style={{overflow: "hidden", paddingLeft: "4px"}}>{id}</label><span style={{paddingLeft: "4px"}} onClick={() => handleClipboard(id)}> {t(t_prefix+'tooltip_copy')}</span>
            </div>
        </>
    )
}

export default CustomToolTip
