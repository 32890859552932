import React from 'react'
import Header from '../header'
import Sidebar from '../sidebar'
import "./index.scss"
import { useLocation } from "react-router-dom";

const HomeLayout = ({children, breadcrumb}) => {
    const location = useLocation()
    const home = "/home";
    let displayMessageIcon = false;



    if(location && location.pathname === home){
        displayMessageIcon = true;
    }


    return(
        <div className="home-layout">

            {displayMessageIcon && <div className="message-icon_container">
                <img alt="message" src={window.location.origin + '/img/messageIcon.svg'} className="message-icon" />
            </div>}

            <Sidebar />

            <div className="header-and-content-container">
                <Header />

                {breadcrumb && <div className="bread-crumb">{breadcrumb}</div>}

                <div className="content-container">
                    {children}
                </div>

            </div>
        </div>
    )
}

export default HomeLayout
