import React, {useContext, useEffect, useState} from 'react';
import Menu from '../menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from "react-router-dom";
import {getItem} from "../../utilities/localStorage";
import {AppContext} from "../../contextHook";
import {TesozApi} from "../../api";
import { useTranslation } from 'react-i18next';
import "./index.scss";

const t_prefix = "header.dropdown_user."

const LanguageAndMenu = () => {
    const [displayProfileMenu, setDisplayProfileMenu] = useState(false)
    const [displayLanguageMenu, setDisplayLanguageMenu] = useState(false)
    const [language, setLanguage] = useState('French')
    const { t, i18n } = useTranslation();


    const {currentUserData, setUserData, setUserLogout} = useContext(AppContext)
    const history = useHistory()

    const token = getItem('token');
    const api = new TesozApi()

    const menuItemsList = [
        {name: t(t_prefix+'dashboard'), to: "/dashboard"},
        {name: t(t_prefix+'my_account'), to: "/myaccount"},
        {name: t(t_prefix+'channel_management'), to: "/channelmanagement"},
        {name: t(t_prefix+'sign_out'), to: "/"}
    ];

    const languageItemsList = [
        {name: "French", to: null},
        {name: "English", to: null}
    ];

    useEffect(() => {
        const lng = window.localStorage.getItem('language')
        if(lng) {
            debugger
            setLanguage(lng)
            if(lng === 'English'){
                i18n.changeLanguage('en')
            }
            if(lng === 'French'){
                i18n.changeLanguage('fr')
            }
        }

        if(token) {
            whoAmI()
        }
    }, [])

    const whoAmI = () => {
        api.Me().then(response => {
            console.log("currentUserData", response)
            console.log("currentUserData data", response.data)
            if(response){
                if(response.status === 200){
                    const { data } = response;
                    setUserData({data: data})
                    console.log("currentUserData data", response.data)

                }
            }
        }).catch(e => {
            console.log("error", e)
            console.log("error", e.response)
            if(e){
                if(e.response){
                    if(e.response.status === 403) {
                        if(e.response.data) {
                            if (e.response.data.message === "Expired token") {
                                setUserLogout()
                                history.push("/")
                            }
                        }
                    }
                }
            }
        })
    }

    const handelProfileMenuOpen = () => {
        setDisplayProfileMenu(true)
        setDisplayLanguageMenu(false)
    }

    const handelProfileMenuClose = () => {
        setDisplayProfileMenu(false)
    }

    const handelLanguageMenuOpen = () => {
        setDisplayLanguageMenu(true)
        setDisplayProfileMenu(false)
    }

    const handelLanguageMenuClose = () => {
        setDisplayLanguageMenu(false)
    }

    const handleLanguageAndProfileMenuClose = () => {
        setDisplayProfileMenu(false)
        setDisplayLanguageMenu(false)
    }

    const handleLanguageChange = (language) => {
        setLanguage(language)
        window.localStorage.setItem('language', language)
        if(language === "English"){
            i18n.changeLanguage('en');
        }

        if(language === "French"){
            i18n.changeLanguage('fr');
        }
        setTimeout(() => {
            setDisplayProfileMenu(false)
            setDisplayLanguageMenu(false)
        }, 100)
    }

    const handleMenuChange = (to, name) => {
        if(to){
            if(to === ("/" || "/login")){
                setUserLogout()
            }
            setTimeout(() => {
                setDisplayProfileMenu(false)
                setDisplayLanguageMenu(false)
            }, 100)
            history.push(to)
        }
        else {
            // handleLanguageAndProfileMenuClose()
            handleLanguageChange(name)
        }
    }

    console.log("abc", currentUserData)

    return (
        <div className="languageAndMenu">
            <div className={`${displayProfileMenu || displayLanguageMenu ? "overlay" : "menu-display-none"}`} onClick={handleLanguageAndProfileMenuClose}></div>
            <div className="language" onClick={handelLanguageMenuOpen} >
                {language} <ExpandMoreIcon className="down-icon" />
                <Menu language={displayLanguageMenu} listItems={languageItemsList} menuChange={handleMenuChange} />
            </div>
            <div className="line" />
            {!token ?
                <div className="menu-item" onClick={() => history.push("/login")}>
                    Login
                </div>
            :
                <div className="menu-item" onClick={handelProfileMenuOpen} >
                    <AccountCircleIcon className="profile-icon" />Hello {currentUserData && currentUserData.data && currentUserData.data.fullName} <ExpandMoreIcon className="down-icon" />
                    <Menu profile={displayProfileMenu} listItems={menuItemsList} menuChange={handleMenuChange} />
                </div>
            }
        </div>
    )
}

export default LanguageAndMenu
