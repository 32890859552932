export function TableDateTimeField ({dateTime, revoked})  {
    return (
            // <>
            //   {dateTime.map(el => {
            //     let color = el.date ? '#707070' : '#B8DFD8';
            //     return <label style={{paddingLeft: el.time ? "6px" : "0",color: color}}>{el.date ? el.date : el.time}</label>
            //   })}
            // </>
        <div style={{color: !revoked ? "#707070" : "#B8DFD8"}}>{dateTime ? dateTime : "Undefined date"}</div>
    )
}

export function Actions ({revoked})  {
    console.log("revoked is", revoked)
    const { actions } = revoked
    return (
        <>
            {!actions &&<img alt="channel management" style={{height: "30px", width: "30px"}} src={window.location.origin + '/img/table-not-allowed-icon.svg'} />}
        </>
    )
}
