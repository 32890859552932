import React from 'react';
import { Spin, Space } from 'antd';

const Loader = ({size}) => {
    //size="middle" size="small" size="large"
    return (
        <Space size="middle">
            {/*<Spin size="small" />*/}
            {/*<Spin />*/}
            <Spin size="large" />
        </Space>
    )
}

export default Loader

