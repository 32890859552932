import React, {useState} from 'react';
import Layout from '../components/layout';
import Container from '@material-ui/core/Container';
import FullWidthGrid from '../components/grid';
import Grid from '@material-ui/core/Grid';
import Stepper from "../components/stepper";
import CheckFiles from "../content/checkFiles";
import CheckFilesError from "../content/checkFilesError";
import ContactUsLink from "../content/contactLink";
import AboutUs from "../content/aboutUs";
import { useTranslation } from 'react-i18next';

const t_prefix = "upload_files.stepper."

const UploadFiles = () => {
    const [step , setStep] = useState(0);
    const [checkFileError , setCheckFileError] = useState(false);
    const { t } = useTranslation();

    const stepper = [
        {name: t(t_prefix+'stepper_1'), id : 0},
        // {name: t(t_prefix+'stepper_2'), id : 1},
        // {name: t(t_prefix+'stepper_3'), id : 2}
    ]

    const setError = () => {
        setCheckFileError(true)
    }

    // const unSetError = () => {
    //     setCheckFileError(false)
    // }


    const handleChangeStep = (index) => {
            setStep(index)
    }

    let renderedComponent;

    if(step === 0){
        if(checkFileError){
            renderedComponent = <CheckFilesError />
        }
        else {
            renderedComponent = <CheckFiles displayError={setError} />
        }
    }

    if(step === 1) {
        renderedComponent = <div style={{paddingTop: "100px"}}><AboutUs /></div>
    }

    if(step === 2){
        renderedComponent = <ContactUsLink />
    }

    return (
        <Layout breadcrumb={null}>
            <div className="qa">
                <Container maxWidth="lg">
                        <FullWidthGrid>
                            <Grid item xs={12}>
                                <div className="upload-file-container">
                                   <div className="upload-file-inner-container">
                                        <div className="upload-file-inner-wrapper-container">
                                            {/*<Stepper stepper={stepper} step={step} error={checkFileError} changeStep={handleChangeStep} />*/}
                                            {/*<h1 style={{textUnderlineOffset: "10px", textDecoration: "underline", paddingTop: "10px", paddingBottom: "30px", color: "#858589", textAlign: "center"}}>{t(t_prefix+'stepper_1')}</h1>*/}
                                            {renderedComponent}
                                        </div>
                                   </div>
                                </div>
                            </Grid>
                        </FullWidthGrid>
                </Container>
            </div>
        </Layout>
    )
}

export default UploadFiles
