import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import "./index.scss"
import {getItem} from "../../utilities/localStorage";

const FixedSidebar = ({toggleSidebarOpen, handleDashboardClick, activeSideBarItem}) => {

    const token = getItem('token');
    console.log("token is : ", token)

    return (
        <div className="sidebar">
               <div className="sidebar-menu-container">
                    <MenuIcon className="sidebar-menu" onClick={toggleSidebarOpen} />
               </div>

               <div className="sidebar-items-container">
                   {token &&
                   <>
                   <div className={`sidebar-items ${activeSideBarItem === 0 ? 'active' : ''}`} onClick={() => handleDashboardClick("/dashboard",0)}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         {activeSideBarItem === 0 ?
                          <img alt="active-side-bar" src={window.location.origin + '/img/dashboardHoverIcon.svg'} className="sidebar-icons" />
                          :
                          <img alt="in-active-side-bar" src={window.location.origin + '/img/dashboardIcon.svg'} className="sidebar-icons" />
                          }

                    </div>
                    <div className={`sidebar-items ${activeSideBarItem === 1 ? 'active' : ''}`} onClick={() => handleDashboardClick("/jobs", 1)}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         {activeSideBarItem === 1 ?
                          <img alt="active-side-bar" src={window.location.origin + '/img/jobsIconActive.svg'} className="sidebar-icons" />
                          :
                          <img alt="in-active-side-bar" src={window.location.origin + '/img/jobsIcon.svg'} className="sidebar-icons" />
                          }

                    </div>
                    <div className={`sidebar-items ${activeSideBarItem === 2 ? 'active' : ''}`} onClick={() => handleDashboardClick("/documentlist", 2)}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         {activeSideBarItem === 2 ?
                          <img alt="active-side-bar" src={window.location.origin + '/img/docListIconActive.svg'} className="sidebar-icons" />
                          :
                          <img alt="in-active-side-bar" src={window.location.origin + '/img/docListIcon.svg'} className="sidebar-icons" />
                          }

                    </div>
                    <div className={`sidebar-items ${activeSideBarItem === 3 ? 'active' : ''}`} onClick={() => handleDashboardClick("/deferreddocspending", 3)}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         {activeSideBarItem === 3 ?
                          <img alt="active-side-bar" src={window.location.origin + '/img/deferredDocsPendingIconActive.svg'} className="sidebar-icons" />
                          :
                          <img alt="in-active-side-bar" src={window.location.origin + '/img/deferredDocsPendingIcon.svg'} className="sidebar-icons" />
                         }

                    </div>
                    <div className={`sidebar-items ${activeSideBarItem === 4 ? 'active' : ''}`} onClick={() => handleDashboardClick("/signaturerequest", 4)}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         {activeSideBarItem === 4 ?
                          <img alt="active-side-bar" src={window.location.origin + '/img/signReqIconActive.svg'} className="sidebar-icons" />
                          :
                          <img alt="in-active-side-bar" src={window.location.origin + '/img/signReqIcon.svg'} className="sidebar-icons" />
                         }

                    </div>
                   </>}
                    <div className={`sidebar-items ${activeSideBarItem === 5 ? 'active' : ''}`} onClick={() => handleDashboardClick("/uploadFiles", 5)}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         {activeSideBarItem === 5 ?
                          <img alt="active-side-bar" src={window.location.origin + '/img/sigCheckActive.svg'} className="sidebar-icons" />
                          :
                          <img alt="in-active-side-bar" src={window.location.origin + '/img/sigCheck.svg'} className="sidebar-icons" />
                         }
                    </div>
                    <div className={`sidebar-items ${activeSideBarItem === 6 ? 'active' : ''}`} onClick={() => handleDashboardClick("/qa", 6)}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         {activeSideBarItem === 6 ?
                          <img alt="active-side-bar" src={window.location.origin + '/img/qAIconActive.svg'} className="sidebar-icons" />
                          :
                          <img alt="in-active-side-bar" src={window.location.origin + '/img/qAIcon.svg'} className="sidebar-icons" />
                         }
                    </div>
                    <div className={`sidebar-items ${activeSideBarItem === 7 ? 'active' : ''}`} onClick={() => handleDashboardClick("/about", 7)}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         {activeSideBarItem === 7 ?
                          <img alt="active-side-bar" src={window.location.origin + '/img/aboutIconActive.svg'} className="sidebar-icons" />
                          :
                          <img alt="in-active-side-bar" src={window.location.origin + '/img/aboutIcon.svg'} className="sidebar-icons" />
                         }
                    </div>
                   {token && <div className={`sidebar-items ${activeSideBarItem === 8 ? 'active' : ''}`} onClick={() => handleDashboardClick("/manageusers", 8)}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         {activeSideBarItem === 8 ?
                          <img alt="active-side-bar" src={window.location.origin + '/img/settingsIconActive.svg'} className="sidebar-icons" />
                          :
                          <img alt="in-active-side-bar" src={window.location.origin + '/img/settingsIcon.svg'} className="sidebar-icons" />
                         }
                    </div>}
               </div>
          </div>
    )
}

export default FixedSidebar
