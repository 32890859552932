export function TableDateTimeField ({dateTime})  {

    let now = dateTime.split(' ');

    return (
        <label>
            <span style={{color: "#707070"}}>{now[0]}</span>
            <span style={{color: "#B8DFD8", paddingLeft: "6px"}}>{now[1]} {now[2]}</span>
        </label>
    )
}

export function Actions ({actions, handleJobDetail, handleJobListDetail, handleMerkelTreeDoc})  {
    return (
      <div>
        <img alt="tree" style={{marginRight: "6px"}} src={window.location.origin + '/img/treeIcon.svg'} className="image-hover" onClick={() => handleMerkelTreeDoc(actions.key)}/>
        <img alt="feather" style={{marginRight: "6px"}} src={window.location.origin + '/img/featherIcon.svg'} className="image-hover" onClick={() => handleJobListDetail(actions.key)}/>
        <img alt="add" src={window.location.origin + '/img/table-add-icon.svg'} className="image-hover" onClick={() => handleJobDetail(actions.key)}/>
      </div>
    )
}
