export function Administrator ({isAdmin}) {
  return (
    <>
    {isAdmin ?
        <img alt="tick" src={window.location.origin + '/img/table-tick-icon.svg'} />
      :
        <img alt="cross" src={window.location.origin + '/img/table-cross-icon.svg'} />}
    </>
  )
}

export function Status ({userStatus, editUser, handleDisableUser, handleActiveUser})  {
  console.log("status user", userStatus)

  const { status, administrator, key } = userStatus;

  return (
      <>
        {administrator ?
            <img alt="admin-toggle" style={{width: "60px", height: "28px", marginTop: "4px", marginRight: "6px"}} className="image-hover_not_allowed" src={window.location.origin + '/img/table-toggle-true-icon.svg'} />:
            <>
              {status ?
                <img alt="toggle-false" onClick={() => handleDisableUser(userStatus, status, key)} style={{width: "60px", height: "28px", marginTop: "4px", marginRight: "6px"}} className="image-hover" src={window.location.origin + '/img/table-toggle-false-icon.svg'} />:
                <img alt="toggle-true" onClick={() => handleActiveUser(userStatus, status, key)} style={{width: "60px", height: "28px", marginTop: "4px", marginRight: "6px"}} className="image-hover" src={window.location.origin + '/img/table-toggle-true-icon.svg'} />
              }
            </>
        }

        {status ?
          <img alt="edit-disable" style={{width: "20px", height: "20px"}} className="image-hover_not_allowed" src={window.location.origin + '/img/table-edit-icon.svg'} /> :
          <img alt="edit-enable" style={{width: "20px", height: "20px"}} className="image-hover" src={window.location.origin + '/img/table-edit-icon.svg'} onClick={() => editUser(key)}/>
        }
      </>
  )
}
