import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import "./index.scss"






export default function Modal({open, handleClose, children}) {


    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                {children}
            </Dialog>
        </div>
    );
}
