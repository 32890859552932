import React from 'react';
import "./index.scss";

const AboutUsTable = ({data}) => {
    const {titleLeft, titleRight, rows} = data;
    return (
        <div className="about-us-table">
            <div className="about-us-table_header">
                <div className="header-left">{titleLeft}</div>
                <div className="header-right">{titleRight}</div>
            </div>
            {rows.map((el, index) => {
                const {descriptionLeft, descriptionRight} = el;
                return (
                    <div className="about-us-table_row" key={index} >
                        <div className="about-us-table-row_left">{descriptionLeft}</div>
                        <div className="about-us-table-row_right">{descriptionRight}</div>
                    </div>
                )
            })}

            {/* <div className="about-us-table_row">
                <div className="about-us-table-row_left">Cost Saving</div>
                <div className="about-us-table-row_right">Document does not need to be sent to Tezos DigiSign to be signed, it does not leave the IS and the company network. No need to archive the signed copy of the document with a third party (the signer should just keep proof of signature)</div>
            </div> */}
        </div>

    )
}

export default AboutUsTable
