import React, {useState} from 'react';
import Container from '@material-ui/core/Container';
import FullWidthGrid from '../components/grid';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {useHistory} from "react-router-dom";
import {TesozApi} from "../api";
import SimpleAlerts from "../components/alerts";
import Loader from "../components/loader";
import {getItem, saveItem} from "../utilities/localStorage"
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const t_prefix = "signIn."

const Signin = () => {
    const classes = useStyles();
    const history = useHistory()
    const api = new TesozApi();
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation();

    const validationSchema = yup.object({
        // email: yup
        //   .string('Enter your email')
        //   .email('Enter a valid email')
        //   .required('Email is required'),
        login: yup
            .string('Enter username')
            .min(5, t(t_prefix+'userName_length'))
            .required(t(t_prefix+'userName_required')),
        password: yup
            .string('Enter your password')
            .min(8, t(t_prefix+'password_length'))
            .required(t(t_prefix+'password_required')),
    });

    const formik = useFormik({
        initialValues: {
          //email: 'foobar@example.com',
          login: '',
        //   password: 'foobar',
          password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          //alert(JSON.stringify(values, null, 2));

          LoginUser(values)
        },
    });

    const LoginUser = (data) => {
        setLoading(true);
        setError(false);
        const values = {
            user: data.login,
            password: data.password
        }

        api.Login(values).then((response) => {
            setLoading(false)
            if (response.status === 200) {
                if(response.data){
                    const { token } = response.data;
                    saveItem('token', token);
                    history.push("/dashboard")
                }

            }
        }).catch((error) => {
            console.log("error", error);
            console.log("error.response", error.response);

            if(error){
                setLoading(false)
                if(error.response){
                    const { response } = error;
                    if(response.data){
                        const {data} = response;
                        if(data){
                            if(data.message){
                                const { message } = data;
                                console.log("message", message)
                                // console.log("status", status)
                                setError(message)
                            }
                            else {
                                setError(t(t_prefix+'something_went_wrong'))
                            }
                        }
                        else {
                            setLoading(false)
                            setError(t(t_prefix+'something_went_wrong'))
                        }
                    }
                    else {
                        setLoading(false)
                        setError(t(t_prefix+'something_went_wrong'))
                    }
                }
                else {
                    setError(t(t_prefix+'something_went_wrong'))
                }
            }
            else {
                setLoading(false)
                setError(t(t_prefix+'something_went_wrong'))
            }
        });
    }

    const token = getItem('token');
    if(token){
        history.push("/dashboard")
    }

    return (
        <div className="signin">
           <Container maxWidth="lg" className="signin-bg-clr">
                <div className="just-signin-logo">
                    <div>
                        <img alt="trimmed new logo" src={window.location.origin + '/img/trimmed-new-logo.png'} className="latest-trimmed-logo" />
                    </div>
                </div>
                <FullWidthGrid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className="image-wrapper">
                            <img alt="signIn" src={window.location.origin + '/img/signinBackground.svg'} className="signin-logo-size" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="new-form-grid-padding">
                        <div className="just-signin-form-container">
                            <label className="just-signin-title">
                                {t(t_prefix+'title')}
                            </label>
                            <p className="just-signin-description">
                                {t(t_prefix+'sub_title')}
                            </p>

                            {error && <div style={{height: "10vh", marginBottom: "10px"}}><SimpleAlerts type="error" message={error} /></div>}

                            <form onSubmit={formik.handleSubmit}>
                                {/* <TextField
                                style={{marginBottom: "12px"}}
                                fullWidth
                                id="email"
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                /> */}
                                <TextField
                                style={{marginBottom: "12px"}}
                                fullWidth
                                id="login"
                                name="login"
                                label={t(t_prefix + 'userName_label')}
                                value={formik.values.login}
                                onChange={formik.handleChange}
                                error={formik.touched.login && Boolean(formik.errors.login)}
                                helperText={formik.touched.login && formik.errors.login}
                                />
                                <TextField
                                style={{marginBottom: "12px"}}
                                fullWidth
                                id="password"
                                name="password"
                                label={t(t_prefix+'password_label')}
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                />
                                <label className="just-signin-forgot-password" onClick={() => {history.push("/forgotpassword")}}>
                                    {t(t_prefix+'forgot_password')}
                                </label>
                                <Button className="btn btn-primary btn-lg new-size-signin-forgt-btns" fullWidth type="submit">
                                    {loading === true ? <Loader color="white" /> : t(t_prefix+'signIn_btn')}
                                </Button>
                            </form>
                            <label className="just-signin-already-account">
                                {t(t_prefix+'already_have_acc')}<span className="already-account_signup"> {t(t_prefix+'signup')}</span>
                            </label>
                        </div>
                    </Grid>
                </FullWidthGrid>
            </Container>
        </div>
    )
}

export default Signin;

