import React from 'react';
import FullWidthGrid from '../../grid';
import Grid from '@material-ui/core/Grid';
import './index.scss'

const Footer = () => {
    return (
        <div className="footer-container">
            <FullWidthGrid >
                <Grid item xs={12} sm={6} md={3}>
                    <div className="footer-title-list-container">
                        <div className="footer-title">
                            MAIN
                        </div>
                        <div className="footer-list-items">
                            <div>Home</div>
                            <div>About</div>
                            <div>Services</div>
                            <div>Projects</div>
                            <div>Blogs</div>
                            <div>Career</div>
                        </div>    
                    </div>     
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="footer-title-list-container">
                    <div className="footer-title">
                        SERVICES
                    </div>
                    <div className="footer-list-items">
                        <div>Branding</div>
                        <div>Content</div>
                        <div>Design</div>
                        <div>Marketing</div>
                        <div>Strategy</div>
                        <div>Technology</div>
                    </div>              
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="footer-title-list-container">
                    <div className="footer-title">
                        FOCUS
                    </div>
                    <div className="footer-list-items">
                        <div>App Design</div>
                        <div>App Development</div>
                        <div>Search Engine</div>
                        <div>Optimization</div>
                        <div>Video Production</div>
                        <div>Website Design</div>
                        <div>Web Development</div>
                    </div>            
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="footer-title-list-container">
                    <div className="footer-title">
                        INDUSTRIES
                    </div>
                    <div className="footer-list-items">
                        <div>Automotive</div>
                        <div>Beauty and Fashion</div>
                        <div>Education & Government</div>
                        <div>Food and Beverage</div>
                        <div>Media and Publications</div>
                        <div>Real Estate</div>
                        <div>Sports and Entertainment</div>
                        <div>Startups</div>
                    </div>         
                </div>
            </Grid>
            </FullWidthGrid>
        </div>
    )
}

export default Footer