import React, {useEffect, useState} from 'react';
import AntdTable from '../../components/Tables/antd-table';
import { Proof } from "./formatter";
import Button from '@material-ui/core/Button';
import {TesozApi} from "../../api";
import {useLocation, useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import {useTranslation} from "react-i18next";
import "./index.scss";

const t_prefix = "doc_list."

const DocumentListContent = () => {
    const [tableLoading, setTableLoading] = useState(false);
    const [documentsList, setDocumentsList] = useState([])
    const [relatedJob, setRelatedJob] = useState(null)
    const [name, setName] = useState(null)
    const [startDate, setStartDate] = useState(null);
    const {t} = useTranslation();

    const location = useLocation()
    const params = useParams();
    // const { index } = params;
    const api = new TesozApi();

    useEffect(() => {
        if(location){
            if(location.state){
                if(location.state.relatedJob){
                    setRelatedJob(location.state.relatedJob)
                    dashboardMe(location.state.relatedJob)
                }
                else {
                    dashboardMe()
                }
            }
            else {
                dashboardMe()
            }
        }
    }, [])

    const dashboardMe = (relatedJob = null, fName = null, sDate=null) => {
        setTableLoading(true)
        api.Me().then(response => {
            setTableLoading(false)
            if(response){
                if(response.status === 200){
                    const { data } = response;
                    if(data && data.id){
                        const { id } = data;
                        documentListFiles(id, relatedJob, fName, sDate)
                    }
                }
            }
        }).catch(e => {
            console.log("error", e)
            setTableLoading(false)
        })
    }

    const documentListFiles = (id, relatedJob = null, fName = null, sDate=null) => {
        setTableLoading(true)
        const params = {
            accountId: id
        }
        params.jobId = relatedJob
        params.name = fName
        params.dateStart = sDate

        api.Files(params).then(response => {
            setTableLoading(false)
            console.log("response documentListFiles", response)
            if(response){
                if(response.data){
                    const { data } = response;
                    let customDocumentList = data.map(el => {

                        const {id, hash, jobId, metadata, jobStateName} = el
                        const { fileName } = metadata;

                        return {
                            key: id,
                            documentName: fileName,
                            documentHash: hash,
                            relatedJob: jobId,
                            proof: {id, jobStateName}
                        }
                    })

                    setDocumentsList(customDocumentList)
                }
            }
        }).catch(e => {
            console.log("e" ,e)
            setTableLoading(false)
        })
    }



    const columns = [
        {
            title: t(t_prefix+'col_doc_name'),
            dataIndex: 'documentName',
            key: 'documentName',
            render: text => <label style={{color: "#707070"}}>{text}</label>
        },
        {
            title: t(t_prefix+'col_doc_hash'),
            dataIndex: 'documentHash',
            key: 'documentHash',
            render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
            title: t(t_prefix+'col_related_job'),
            dataIndex: 'relatedJob',
            key: 'relatedJob',
            render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
            title: t(t_prefix+'col_proof'),
            dataIndex: 'proof',
            key: 'proof',
            render: ({id, jobStateName}) => <Proof proof={id} jobState={jobStateName} onClick={handleProofDocDownload} />
        }
    ];

    const handleProofDocDownload = (id) => {
        console.log("id : ", id)
        setTableLoading(true)
        if(id){
            api.Proof(id).then(response => {
                setTableLoading(false)
                console.log("response", response)
                if(response){
                    if(response.status === 200 && response.data){
                        downloadObjectAsJson(response.data, "dummy_file")
                    }
                    setTableLoading(false)
                }

            }).catch(e => {
                console.log("error", e)
                setTableLoading(false)
            })
        }
    }

    const downloadObjectAsJson = (exportObj, exportName) => {
        setTableLoading(true)
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
        setTableLoading(false)
    }

    const handleDateChange = (date) => {
        setStartDate(date)
    }

    const handleSearch = () => {
        let customDate = startDate ? (moment(startDate).format('YYYY-MM-DD')) : null
        dashboardMe(relatedJob, name, customDate)
    }

    console.log("documentsList 1", documentsList)
    console.log("relatedJob 1", relatedJob)

    return (
        <div className="document-list-content">
          <div className="btns-container">
          <div className="left">
                <input type="text" placeholder={t(t_prefix+'input_rel_job_placeholder')} className="input-custom-generic input-left-bordered by-name by-related-job" value={relatedJob} onChange={(e) => setRelatedJob(e.target.value)}/>
                {/*<Button className="btn btn-outline-primary jobs-br-left by-related-job btns-font-size">Related Job</Button>*/}

              <input type="text" placeholder={t(t_prefix+'input_name_placeholder')} className="input-custom-generic input-normal-bordered by-doc-name" value={name} onChange={(e) => setName(e.target.value)}/>
              {/*<Button className="btn btn-outline-primary by-doc-name btns-font-size" >document name</Button>*/}

              <div className="by-creation-date-range" style={{display: "flex"}}>
                  <DatePicker
                      className="input-custom-generic input-right-bordered input-date-width"
                      selected={startDate}
                      onChange={handleDateChange}
                      placeholderText={t(t_prefix+'input_cr_date_placeholder')}
                      dateFromat='YYYY-MM-DD'
                  />
              </div>
              {/*<Button className="btn btn-outline-primary jobs-br-right by-creation-date-range btns-font-size">Creation Date Range</Button>*/}

              <Button className="btn btn-primary contct-us-rounded by-search btns-font-size" onClick={handleSearch}>{t(t_prefix + 'search_btn')}</Button>
              </div>
              <div className="right">
                <Button className="btn btn-primary contct-us-rounded by-export btns-font-size">{t(t_prefix+'export_btn')}</Button>
              </div>
          </div>
            <AntdTable columns={columns} data={documentsList} loading={tableLoading} />
        </div>
    )
}

export default DocumentListContent
