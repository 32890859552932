import * as axios from "axios";
import {getItem} from "./utilities/localStorage";

export class TesozApi {

    Login = (data) => {
        const url = 'https://tezos.yodaledger.com/api/auth';

        return axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
        })
    };

    Me = () => {
        const token = getItem('token');
        const url = 'https://tezos.yodaledger.com/api/me';

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    };

    Dashboard = () => {
        const token = getItem('token');
        const url = 'https://tezos.yodaledger.com/api/stats/dashboard';

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    };

    Job = (id) => {
        const token = getItem('token');
        const url = `https://tezos.yodaledger.com/api/jobs/${id}`;

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
    };

    Jobs = (params) => {
        const token = getItem('token');
        const url = 'https://tezos.yodaledger.com/api/jobs';

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: params
        })
    };

    JobsCount = (params) => {
        console.log("params", params)
        const token = getItem('token');
        const url = 'https://tezos.yodaledger.com/api/jobs-count';

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: params
        })
    };

    Tokens = () => {
        const token = getItem('token');
        const url = 'https://tezos.yodaledger.com/api/tokens';

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
    };

    Revoke = (id) => {
        const token = getItem('token');
        const url = `https://tezos.yodaledger.com/api/tokens/${id}/revoke`;

        return axios({
            method: 'post',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
    };

    CreateNewToken = (data) => {
        const token = getItem('token');
        const url = 'https://tezos.yodaledger.com/api/tokens';

        return axios({
            method: 'post',
            url: url,
            data: data,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
    };

    Check = (data) => {
        const url = 'https://tezos.yodaledger.com/api/check';

        return axios({
            method: 'post',
            url: url,
            data: data,
            responseType: 'json',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }

    Files = (params) => {
        const token = getItem('token');
        const url = 'https://tezos.yodaledger.com/api/files';

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: params
        })
    };

    DeferredFiles = (params) => {
        const token = getItem('token');
        const url = 'https://tezos.yodaledger.com/api/deferred/files';

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: params
        })
    };

    Proof = (id) => {
        const token = getItem('token');
        const url = `https://tezos.yodaledger.com/api/files/${id}/proof`;

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
    };

    MerkelTree = (id) => {
        const token = getItem('token');
        const url = `https://tezos.yodaledger.com/api/jobs/${id}/merkelTree`;

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
    };

    GetAccount = (id) => {
        const token = getItem('token');
        const url = `https://tezos.yodaledger.com/api/accounts/${id}`;

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    }

    EditAccount = (id, data) => {
        const token = getItem('token');
        const url = `https://tezos.yodaledger.com/api/accounts/${id}`;

        return axios({
            method: 'patch',
            url: url,
            data: data,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    };

    AddAccount = (data) => {
        const token = getItem('token');
        const url = `https://tezos.yodaledger.com/api/accounts`;

        return axios({
            method: 'post',
            url: url,
            data: data,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    };

    DocumentListProof = (id) => {
        const token = getItem('token');
        const url = `https://tezos.yodaledger.com/api/files/${id}/proof`;

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    };

    DocumentListFiles = (deferred, params) => {
        const token = getItem('token');
        const url = deferred ? 'https://tezos.yodaledger.com/api/deferred/files' : 'https://tezos.yodaledger.com/api/files';

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    };

    DocumentListFilesCount = (deferred, params) => {
        const token = getItem('token');
        const url = deferred ? 'https://tezos.yodaledger.com/api/deferred/files-count' : 'https://tezos.yodaledger.com/api/files-count';

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    };

    SignRetry = (jobId) => {
        const token = getItem('token');
        const url = 'https://tezos.yodaledger.com/api/sign/retry';

        return axios({
            method: 'post',
            url: url,
            data: jobId,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    };

    Accounts = () => {
        const token = getItem('token');
        const url = 'https://tezos.yodaledger.com/api/accounts';

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    };

    CertiffUserGet = (baliffName) => {
        const url = `https://app.ceertif.com/api-v2/get_bailiff_logo_by_name.php?bailiff_name=${baliffName}`;

        return axios({
            method: 'get',
            url: url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    };

}
