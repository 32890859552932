import React, {useState} from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import "./index.scss";



const CheckFileError = ({title, detail, icon, successIcon, furtherDetail}) => {
    const [display, setDisplay] = useState(false)
    const handleFurtherDetail = () => {
        setDisplay(!display)
    }

    return (
        <div className="file-not-found" >
            <div className={`file-title ${(icon || successIcon) ? "file-title-main" : "file-title-sub" }`}>
                {icon && <CancelIcon className="cancel-icon" />} {successIcon && <CheckCircleIcon className="cancel-icon" />} {title}
            </div>
            <div className="arrow-down-container">
                <div className="down-triangle" />
            </div>
            <div className={` file-detail ${icon  ? "file-detail-main" : "file-detail-sub"}`}>
                {detail.map((el, i) => {
                    const {data} = el;
                    return (<div key={i} style={{lineBreak: "anywhere"}}>{data}</div>)
                })}
            </div>
            {furtherDetail &&
                <div className={`file-detail-sub`} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <label onClick={handleFurtherDetail} style={{display: "flex"}}>More Details
                        <>
                            {display ? <ExpandLessIcon />: <ExpandMoreIcon />}
                        </>
                    </label>
                </div>
            }
            {furtherDetail && display &&
                <div className={`file-detail-sub`} style={{transition: "visibility 0s opacity 0.5s linear", opacity: 1}}>{furtherDetail.map((el, i) => {
                    const {data} = el;
                    return (<div className={` file-detail `} style={{lineBreak: "anywhere", paddingBottom: "6px"}} key={i}>{data}</div>)
                })}
                </div>}

        </div>
    )
}

export default CheckFileError
