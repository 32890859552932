import React, {useState, useEffect} from 'react'
import "./index.scss"
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useLocation } from "react-router-dom";
import FixedSidebar from "../fixedSidebar"
import ToggleSidebar from '../toggleSidebar';

const Sidebar = () => {
     const history = useHistory()
     const location = useLocation()
     const [openSidebar, setOpenSidebar] = useState(false)
     const [activeSideBarItem, setActiveSideBarItem] = useState(null)

     useEffect(() => {
          if(location && location.pathname === "/dashboard"){
               setActiveSideBarItem(0)
          }
          else if(location && location.pathname === "/jobs"){
               setActiveSideBarItem(1)
          }
          else if(location && location.pathname === "/documentlist"){
               setActiveSideBarItem(2)
          }
          else if(location && location.pathname === "/deferreddocspending"){
               setActiveSideBarItem(3)
          }
          else if(location && location.pathname === "/signaturerequest"){
               setActiveSideBarItem(4)
          }
          else if(location && location.pathname === "/uploadFiles"){
               setActiveSideBarItem(5)
          }
          else if(location && location.pathname === "/qa"){
               setActiveSideBarItem(6)
          }
          else if(location && location.pathname === "/about"){
               setActiveSideBarItem(7)
          }
          else if(location && location.pathname === "/manageusers"){
               setActiveSideBarItem(8)
          }
     }, [])

     const toggleSidebarOpen = () => {
          setOpenSidebar(open => open = true)
     }

     const toggleSidebarClose = () => {
          setOpenSidebar(open => open = false)
     }
    
     const handleDashboardClick = (route, id) => {
          setActiveSideBarItem(id)
          history.push(route)
     }


    return (
         <>
          <FixedSidebar toggleSidebarOpen={toggleSidebarOpen} handleDashboardClick={handleDashboardClick} activeSideBarItem={activeSideBarItem} />
          <ToggleSidebar open={openSidebar} toggleSidebarClose={toggleSidebarClose} />
        </>
    )
}

export default Sidebar