import React, {useEffect, useState} from 'react';
import AntdTable from '../../components/Tables/antd-table';
import Button from '@material-ui/core/Button';
import {TesozApi} from "../../api";
import "./index.scss";
import {useTranslation} from "react-i18next";



// const data = [
//     {
//       key: '1',
//       documentname: 'Arsahd_resume.pdf',
//       documenthash: 'hjhjhhjhjhjhsssssssssssssss',
//
//     },
//     {
//       key: '2',
//       documentname: 'Maheen_cv.pdf',
//       documenthash: 'hjyhjyhjyhjysssssssssssssssss'
//     },
//     {
//       key: '3',
//       documentname: 'John_cv.pdf',
//       documenthash: 'hjhjhhjhjhjhyusssssssssssssssi'
//     }
// ];

const t_prefix = "deferred_documents_pending."

const DeferredDocsPendingContent = () => {
    const [loading, setLoading] = useState(false)
    const [deferredDocs, setDeferredDocs] = useState([])
    const {t} = useTranslation()
    const api = new TesozApi()

    useEffect(() => {

        whoAmI()

    }, [])

    const whoAmI = () => {
        api.Me().then(response => {
            console.log("response", response)
            if(response){
                if(response.status === 200 && response.data){
                    const {data} = response;
                    if(data){
                        const { id } = data;
                        getDeferredFiles(id)
                    }
                }
            }
        }).catch(e => {
            console.log("e", e)
        })
    }

    const getDeferredFiles = (id) => {
        setLoading(true)
        const params = {
            accountId: id
        }
        api.DeferredFiles(params).then(response => {
            setLoading(false)
            if(response){
                if(response.data){
                    const {data} = response
                    setDeferredDocs(data)
                }
            }
        }).catch(e => {
            console.log("e", e)
            setLoading(false)
        })

    }

    const columns = [
        {
            title: t(t_prefix+'tbl_col_doc_name'),
            dataIndex: 'documentname',
            key: 'documentname',
            render: text => <label style={{color: "#707070"}}>{text}</label>
        },
        {
            title: t(t_prefix+'tbl_col_doc_hash'),
            dataIndex: 'documenthash',
            key: 'documenthash',
            render: text => <label style={{color: "#707070"}}>{text}</label>,
        }
    ];

    return (
        <div className="deferred-docs-pending">
          <div className="btns-container">
              <div className="left">
                <Button className="btn btn-outline-primary jobs-br-left by-related-job btns-font-size">{t(t_prefix+'rel_job_placeholder')}</Button>
                <Button className="btn btn-outline-primary by-doc-name btns-font-size" >{t(t_prefix+'doc_name_placeholder')}</Button>
                <Button className="btn btn-outline-primary jobs-br-right by-creation-date-range btns-font-size">{t(t_prefix+'cr_date_range_placeholder')}</Button>
                <Button className="btn btn-primary contct-us-rounded by-search btns-font-size">{t(t_prefix+'search_btn')}</Button>
              </div>
              <div className="right">
                <Button className="btn btn-primary contct-us-rounded by-export btns-font-size">{t(t_prefix+'export_btn')}</Button>
              </div>
            </div>
            <AntdTable columns={columns} data={deferredDocs} loading={loading} />
        </div>
    )
}

export default DeferredDocsPendingContent
