import React from 'react';
// import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { DropzoneAreaBase} from 'material-ui-dropzone';
import {useTranslation} from "react-i18next";
import "./index.scss";

const t_prefix = "signature_request."

// const theme = createMuiTheme({
//     overrides: {
//         MuiDropzoneSnackbar: {
//             errorAlert: {
//                 backgroundColor: "#AFA",
//                 color: "#000"
//             },
//             successAlert: {
//                 backgroundColor: "#FAA",
//                 color: "#000"
//             },
//         }
//     }
// });

const FileUploadDropzone = ({handleChange, title, signatureReq, disabled, fileObjects, onAdd, onDelete}) => {
    const {t} = useTranslation();

    const handleGetFileAddedMessage = (fileName) => {
        console.log("fileName", fileName)
        return `${fileName} ${t(t_prefix+'file_success_message')}`
    }

    return (
        <>
            <div className={`file-upload-dropzone-title ${signatureReq ? "file-sign-re-margin" : "file-margin"}`} >{title}</div>
            {/*<MuiThemeProvider theme={theme}>*/}
            <DropzoneAreaBase
                showPreviewsInDropzone={false}
                fileObjects={fileObjects}
                onAdd={onAdd}
                onDelete={onDelete}
                dropzoneProps={ { disabled: disabled} }
                filesLimit={10}
                dropzoneText={t(t_prefix+'dir_drag_and_drop')}
                getFileAddedMessage={handleGetFileAddedMessage}
                alertSnackbarProps={{anchorOrigin:{ vertical: 'top', horizontal: 'right' }}}
                // showAlerts={true}
            />
            {/*</MuiThemeProvider>*/}
        </>
    )
}

export default FileUploadDropzone;
