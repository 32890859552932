import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useLocation } from "react-router-dom";
import {getItem} from "../../utilities/localStorage";
import {useTranslation} from "react-i18next";
import "./index.scss";

const t_prefix = 'header.toggle_sidebar.'

const ToggleSidebar = ({open, toggleSidebarClose}) => {
     const history = useHistory()
    const { t } = useTranslation();
     const location = useLocation()

     const token = getItem('token');

     const handleNavigation = (path) => {
          history.push(path)
     }

     return(
        <div className={` ${open ? 'sidebarOpen' : 'sidebarClose'} `}>
               <div className="close-icon_container">
                    <CloseIcon className="close-icon" onClick={toggleSidebarClose} />
               </div>
               <div className={`${token ? "items-container" :  "logout-items-container"}`}>
                    {token && <>
                    <div className="sidebar-item" onClick={() => handleNavigation("/dashboard")} >
                         {/* <MenuIcon className="sidebar-icons" />  */}
                         <img alt="dashboard" src={window.location.origin + '/img/dashboardIcon.svg'} className="sidebar-icons" />
                         <div className="icon-text">{t(t_prefix+'dashboard')}</div>
                    </div>
                    <div className="sidebar-item" onClick={() => handleNavigation("/jobs")} >
                         {/* <MenuIcon className="sidebar-icons" />  */}
                         <img alt="jobs" src={window.location.origin + '/img/jobsIcon.svg'} className="sidebar-icons" />
                         <div className="icon-text">{t(t_prefix+'job_list')}</div>
                    </div>
                    <div className="sidebar-item" onClick={() => handleNavigation("/documentlist")}>
                         {/* <MenuIcon className="sidebar-icons" />  */}
                         <img alt="jobs list" src={window.location.origin + '/img/docListIcon.svg'} className="sidebar-icons" />
                         <div className="icon-text">{t(t_prefix+'document_list')}</div>
                    </div>
                    <div className="sidebar-item"  onClick={() => handleNavigation("/deferreddocspending")}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         <img alt="deferred pending" src={window.location.origin + '/img/deferredDocsPendingIcon.svg'} className="sidebar-icons" />
                         <div className="icon-text">{t(t_prefix+'document_documents')}</div>
                    </div>
                    <div className="sidebar-item" onClick={() => handleNavigation("/signaturerequest")}>
                         {/* <MenuIcon className="sidebar-icons" />  */}
                         <img alt="sign request" src={window.location.origin + '/img/signReqIcon.svg'} className="sidebar-icons" />
                         <div className="icon-text">{t(t_prefix+'signature_req')}</div>
                    </div>
                    <div className="sidebar-item" onClick={() => handleNavigation("/uploadFiles")}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         <img alt="sign check" src={window.location.origin + '/img/sigCheck.svg'} className="sidebar-icons" />
                         <div className="icon-text">{t(t_prefix+'signature_check')}</div>
                    </div>
                    </>}
                    <div className="sidebar-item" onClick={() => handleNavigation("/qa")}>
                         {/* <MenuIcon className="sidebar-icons" />  */}
                         <img alt="qa" src={window.location.origin + '/img/qAIcon.svg'} className="sidebar-icons" />
                         <div className="icon-text">{t(t_prefix+'q_and_a')}</div>
                    </div>
                    <div className="sidebar-item" onClick={() => handleNavigation("/about")}>
                         {/* <MenuIcon className="sidebar-icons" /> */}
                         <img alt="about" src={window.location.origin + '/img/aboutIcon.svg'} className="sidebar-icons" />
                         <div className="icon-text">{t(t_prefix+'resources')}</div>
                    </div>
                    {token &&
                    <div className="sidebar-item" onClick={() => handleNavigation("/myaccount")}>
                         {/* <MenuIcon className="sidebar-icons" />  */}
                         <img alt="settings" src={window.location.origin + '/img/settingsIcon.svg'} className="sidebar-icons" />
                         <div className="icon-text">{t(t_prefix+'settings')}</div>
                    </div>}
               </div>
          </div>
    )
}

export default ToggleSidebar;
