import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const contactUsSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    email: Yup.string().email('Invalid email').required('Required'),
    phoneNo: Yup.string()
    .required('Required *')
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
      textarea: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required *')
  });

export const editMyAccount = Yup.object().shape({
    firstName: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    lastName: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    login: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    email: Yup.string().email('Invalid email').required('Required *'),
    company: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    country: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    profile: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    signatureLimit: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    tesozPublicKey: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    tesozAccount: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    password: Yup.string().required('Required *'),
    passwordConfirmation: Yup.string().required('Required *').oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

export const editUserAccount = Yup.object().shape({
  fullName: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
  // lastName: Yup.string()
  //     .required('Required *')
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!'),
  login: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
  email: Yup.string().email('Invalid email').required('Required *'),
  // company: Yup.string()
  //     .required('Required *')
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!'),
  // country: Yup.string()
  //     .required('Required *')
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!'),
  // profile: Yup.string()
  //     .required('Required *')
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!'),
    signatureLimit: Yup.number()
        .positive()
        .integer(),
  // tesozPublicKey: Yup.string()
  //     .required('Required *')
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!'),
  // tesozAccount: Yup.string()
  //     .required('Required *')
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!'),
  // password: Yup.string().required('Required *'),
  // passwordConfirmation: Yup.string().required('Required *').oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export const addUserAccount = Yup.object().shape({
    login: Yup.string()
        .required('Required *')
        .min(2, 'Too Short!')
        .max(50, 'Too Long!'),
    email: Yup.string().email('Invalid email').required('Required *'),
    signatureLimit: Yup.number()
        .positive()
        .integer(),
        // .min(1, "Invalid.")
        // .max(31, "Invalid."),
    // useKeysInConfig: Yup.string()
    //     .required('Required *')
    //     .min(2, 'Too Short!')
    //     .max(50, 'Too Long!'),
    // tesozPublicKey: Yup.string()
    //   .required('Required *')
    //   .min(2, 'Too Short!')
    //   .max(50, 'Too Long!'),
    // tesozPrivateKey: Yup.string()
    //   .required('Required *')
    //   .min(2, 'Too Short!')
    //   .max(50, 'Too Long!'),
    fullName: Yup.string()
        .required('Required *')
        .min(2, 'Too Short!')
        .max(50, 'Too Long!'),
  // password: Yup.string().required('Required *'),
  // passwordConfirmation: Yup.string().required('Required *').oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export const signatureReqSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    lastName: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    hashAlgorithm: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    data: Yup.string()
      .required('Required *')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    email: Yup.string().email('Invalid email').required('Required'),
  });
