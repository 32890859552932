import React from 'react';
import { useTranslation } from 'react-i18next';
import "./index.scss"

const t_prefix = "upload_files.upload_text."

const FileUpload = ({onChange, onClick, value}) => {
    const { t } = useTranslation();
    return (
        <>
            <label htmlFor="upload-photo" onClick={onClick}><span className="upload-text_span">{t(t_prefix+'btn')}</span> {t(t_prefix+'proof_file')}</label>
            <input type="file" name="photo" id="upload-photo" value={value} onChange={onChange} />
        </>
    )
}

export default FileUpload
