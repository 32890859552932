import React, { useState, useEffect } from 'react';
import AntdTable from "../../components/Tables/antd-table";
import { Status, Administrator } from "./formatter";
import {TesozApi} from "../../api";
import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import Modal from "../../components/modal";
import MuiDialogActions from '@material-ui/core/DialogActions';
import {useTranslation} from "react-i18next";
import "./index.scss"

const t_prefix = "manage_users."

const ManageUsersContent = () => {
    const [loading, setLoading] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const [uId, setUId] = useState(null);
    const history = useHistory()
    const {t} = useTranslation()
    const api = new TesozApi()

    useEffect(() => {
        SettingsAccounts()
    }, [])

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const SettingsAccounts = () => {

        setLoading(true)

        api.Accounts().then(response => {
            setLoading(false)
            if(response){
                const {status, data} = response;
                if(status === 200){
                    if(data){
                        let customAccounts = data.map(acc => {
                            const {id, login, email, fullName, isAdmin, disabled, company, country, firstLogin, hash, publicKey, tezosAccountState} = acc;

                            return {
                                key: id,
                                login,
                                email,
                                fullName,
                                administrator: isAdmin,
                                status: disabled,
                                company,
                                country,
                                firstLogin,
                                hash,
                                publicKey,
                                tezosAccountState
                            }
                        })

                        setAccounts(customAccounts)
                    }
                }
            }
        }).catch(e => {
            console.log("error dashboard", e)
            setLoading(false)
        })
    }

    const columns = [
        {
          title: t(t_prefix+'tbl_col_login'),
          dataIndex: 'login',
          key: 'login',
          render: text => <label style={{color: "#707070"}}>{text}</label>,
          // render: text => <a>{text}</a>,
        },
        {
          title: t(t_prefix+'tbl_col_email'),
          dataIndex: 'email',
          key: 'email',
          render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
          title: t(t_prefix+'tbl_col_full_name'),
          dataIndex: 'fullName',
          key: 'fullName',
          render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
          title: t(t_prefix+'tbl_col_administrator'),
          key: 'administrator',
          dataIndex: 'administrator',
          render: isAdmin => <Administrator isAdmin={isAdmin}/>,
        },
        {
          title: t(t_prefix+'tbl_col_status'),
          key: 'status',
          render: userStatus => <Status userStatus={userStatus} editUser={handleEditUser} handleDisableUser={handleDisableUser} handleActiveUser={handleActiveUser} />
        },
    ];

    const handleEditUser = (id) => {
        console.log("edit user id", id)
        history.push(`edit/manageusers/${id}`)
    }

    // to activate the user
    const handleDisableUser = (userData, isUserDisabled, id) => {
        console.log("userData is ", userData)
        console.log("handleDisableUser is ", isUserDisabled)
        console.log("userId", id)
        const { key, company, country, email, fullName, isAdmin } = userData
        const userObj = {
            company,
            country,
            email,
            fullName,
            isAdmin,
            disabled: false
        }
        console.log("userObj is ", userObj)
        handleStateChangeUser(key ,userObj)
    }

    // to de-activate the user
    const handleActiveUser = (userData, isUserDisabled, id) => {
        console.log("userData is ", userData)
        console.log("handleActiveUser is ", isUserDisabled)
        console.log("userId", id)

        const { key, company, country, email, fullName, isAdmin } = userData
        const userObj = {
            company,
            country,
            email,
            fullName,
            isAdmin,
            disabled: true
        }

        setUId(key)
        setData(userObj)

        //open modal
        handleClickOpen()
    }

    const handleStateChangeUser = (id, data) => {
        console.log("final id is : ", id)
        console.log("final data is : ", data)
        setLoading(true)
        api.EditAccount(id, data).then(response => {
            console.log("response", response)
            setLoading(false)
            if(response){
                if(response.status === 200 && response.data){
                    handleClose()
                    SettingsAccounts()
                }
            }
        }).catch(e => {
            console.log("e", e)
            setLoading(false)
        })
    }

    return (
        <div className="manage-users">
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                <Button className="btn btn-primary" style={{fontSize: "18px", padding: "auto 20px"}} onClick={() => history.push("/addNewUser")}> <AddIcon style={{marginRight: "4px"}}/>{t(t_prefix+'add_btn')}</Button>
            </div>
            <div className="title">{t(t_prefix+'tbl_title')}</div>
            <AntdTable columns={columns} data={accounts} loading={loading} />

            <Modal open={open} handleOpen={handleClickOpen} handleClose={handleClose}>
                <div className="header-title" style={{backgroundColor: "#40BAD5", color: "white", fontSize :"24px"}}>
                    <label style={{fontSize: "24px!important"}}>{t(t_prefix+'edit_disable_acc')}</label><CloseIcon className="image-hover" onClick={handleClose} />
                </div>
                <DialogContent dividers>
                    <Typography gutterBottom style={{paddingRight: "50px", marginTop: "30px", marginBottom: "50px", fontSize: "18px", color: "#707070"}}>
                        {t(t_prefix+'sure_disable_acc')}
                    </Typography>
                </DialogContent>
                <DialogActions style={{margin: "10px 10px"}}>
                    <Button className="btn btn-secondary-red" style={{padding: "6px 20px", fontSize: "18px"}} onClick={handleClose} color="primary">
                        {t(t_prefix+'cancel_btn')}
                    </Button>
                    <Button className="btn btn-secondary" style={{marginLeft: "20px", padding: "6px 20px", fontSize: "18px"}} onClick={() => handleStateChangeUser(uId, data)} color="primary">
                        {t(t_prefix+'confirm_btn')}
                    </Button>
                </DialogActions>
            </Modal>

        </div>
    )
}

export default ManageUsersContent
