import React from 'react';
import AboutUsTable from "../aboutUsTable";
import { useTranslation } from 'react-i18next';
import "./index.scss";

const t_prefix_data_first = "upload_files.about_us.data.first."
const t_prefix_data_second = "upload_files.about_us.data.second."
const t_prefix_data_third = "upload_files.about_us.data.third."
const t_prefix_data_fourth = "upload_files.about_us.data.fourth."

const t_prefix_table_data_first = "upload_files.about_us.table_data.first."
const t_prefix_table_data_second = "upload_files.about_us.table_data.second."
const t_prefix_table_data_third = "upload_files.about_us.table_data.third."

const AboutUs = () => {
    const { t } = useTranslation();

    const aboutUsData = [
        {
            title: t(t_prefix_data_first+'title'),
            description: [
                {text: t(t_prefix_data_first+'desc_1')},
                {text: t(t_prefix_data_first+'desc_2')},
                {text: t(t_prefix_data_first+'desc_3')},
                {text: t(t_prefix_data_first+'desc_4')},
                {text: t(t_prefix_data_first+'desc_5')},
                {text: t(t_prefix_data_first+'desc_6')}
            ]
        },
        {
            title: t(t_prefix_data_second+'title'),
            description: [
                {text: t(t_prefix_data_second+'desc_1')}
            ]

        },
        {
            title: t(t_prefix_data_third+'title'),
            description: [
                {text: t(t_prefix_data_third+'desc_1')}
            ]

        },
        {
            title: t(t_prefix_data_fourth+'title'),
            description: [
                {text: t(t_prefix_data_fourth+'desc_1')},
                {text: t(t_prefix_data_fourth+'desc_2')},
                {text: t(t_prefix_data_fourth+'desc_3')},
                {text: t(t_prefix_data_fourth+'desc_4')},
                {text: t(t_prefix_data_fourth+'desc_5')},
                {text: t(t_prefix_data_fourth+'desc_6')},
                {text: t(t_prefix_data_fourth+'desc_7')},
                {text: t(t_prefix_data_fourth+'desc_8')},
                {text: t(t_prefix_data_fourth+'desc_9')},
                {text: t(t_prefix_data_fourth+'desc_10')},
                {text: t(t_prefix_data_fourth+'desc_11')}
            ]

        }
    ]

    const aboutUsTable = [
        {
            titleLeft: t(t_prefix_table_data_first+'title_left'),
            titleRight: t(t_prefix_table_data_first+'title_right'),
            rows: [
                {
                    descriptionLeft: t(t_prefix_table_data_first+'desc_left_1'),
                    descriptionRight: t(t_prefix_table_data_first+'desc_right_1'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_first+'desc_left_2'),
                    descriptionRight: t(t_prefix_table_data_first+'desc_right_2'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_first+'desc_left_3'),
                    descriptionRight: t(t_prefix_table_data_first+'desc_right_3'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_first+'desc_left_4'),
                    descriptionRight: t(t_prefix_table_data_first+'desc_right_4'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_first+'desc_left_5'),
                    descriptionRight: t(t_prefix_table_data_first+'desc_right_5'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_first+'desc_left_6'),
                    descriptionRight: t(t_prefix_table_data_first+'desc_right_6'),
                }
            ]
        },
        {
            title: t(t_prefix_table_data_second+'title'),
            titleLeft: t(t_prefix_table_data_second+'title_left'),
            titleRight: t(t_prefix_table_data_second+'title_right'),
            rows: [
                {
                    descriptionLeft: t(t_prefix_table_data_second+'desc_left_1'),
                    descriptionRight: t(t_prefix_table_data_second+'desc_right_1'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_second+'desc_left_2'),
                    descriptionRight: t(t_prefix_table_data_second+'desc_right_2'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_second+'desc_left_3'),
                    descriptionRight: t(t_prefix_table_data_second+'desc_right_3'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_second+'desc_left_4'),
                    descriptionRight: t(t_prefix_table_data_second+'desc_right_4'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_second+'desc_left_5'),
                    descriptionRight: t(t_prefix_table_data_second+'desc_right_5'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_second+'desc_left_6'),
                    descriptionRight: t(t_prefix_table_data_second+'desc_right_6'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_second+'desc_left_7'),
                    descriptionRight: t(t_prefix_table_data_second+'desc_right_7'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_second+'desc_left_8'),
                    descriptionRight: t(t_prefix_table_data_second+'desc_right_8'),
                }
            ]
        },
        {
            title: t(t_prefix_table_data_third+'title'),
            titleLeft: t(t_prefix_table_data_third+'title_left'),
            titleRight: t(t_prefix_table_data_third+'title_right'),
            rows: [
                {
                    descriptionLeft: t(t_prefix_table_data_third+'desc_left_1'),
                    descriptionRight: t(t_prefix_table_data_third+'desc_right_1'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_third+'desc_left_2'),
                    descriptionRight: t(t_prefix_table_data_third+'desc_right_2'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_third+'desc_left_3'),
                    descriptionRight: t(t_prefix_table_data_third+'desc_right_3'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_third+'desc_left_4'),
                    descriptionRight: t(t_prefix_table_data_third+'desc_right_4'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_third+'desc_left_5'),
                    descriptionRight: t(t_prefix_table_data_third+'desc_right_5'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_third+'desc_left_6'),
                    descriptionRight: t(t_prefix_table_data_third+'desc_right_6'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_third+'desc_left_7'),
                    descriptionRight: t(t_prefix_table_data_third+'desc_right_7'),
                },
                {
                    descriptionLeft: t(t_prefix_table_data_third+'desc_left_8'),
                    descriptionRight: t(t_prefix_table_data_third+'desc_right_8'),
                }
            ]
        }
    ]

    return (
        <div className="aboutUs">
            <div className="about-us_title">{aboutUsData[0].title}</div>
                {aboutUsData[0].description.map((el, index) => {
                    const {text} = el;
                    return (
                            <div className="about-us_detail" key={index}>{text}</div>
                    )
                })}

            <div className="about-us_title">{aboutUsData[1].title}</div>
                {aboutUsData[1].description.map((el, index) => {
                    const {text} = el;
                    return (
                            <div className="about-us_detail" key={index}>{text}</div>
                    )
                })}

            <AboutUsTable data={aboutUsTable[0]} />

            <div className="about-us_title">{aboutUsData[2].title}</div>
                {aboutUsData[2].description.map((el, index) => {
                    const {text} = el;
                    return (
                            <div className="about-us_detail" key={index}>{text}</div>
                    )
                })}

            <div className="about-us_title">{aboutUsTable[1].title}</div>
            <AboutUsTable data={aboutUsTable[1]} />

            <div className="about-us_title">{aboutUsTable[2].title}</div>
            <AboutUsTable data={aboutUsTable[2]} />

            <div className="about-us_title">{aboutUsData[3].title}</div>
                {aboutUsData[3].description.map((el, index) => {
                    const {text} = el;
                    return (
                            <div className="about-us_detail" key={index}>{text}</div>
                    )
                })}


        </div>
    )
}

export default AboutUs
