import React from 'react';
import Layout from '../components/layout';
import Container from '@material-ui/core/Container';
import FullWidthGrid from '../components/grid';
import Grid from '@material-ui/core/Grid';
import JobStatsContainer from "../components/jobStats"
import {useTranslation} from "react-i18next";

const t_prefix = "q_and_a."

const QualityAndAssurance = () => {
    const {t} = useTranslation();

     const questionAnswers = [
        {title: t(t_prefix+'q_1'), value: t(t_prefix+'a_1'), qa: true},
        {title: t(t_prefix+'q_2'), value: t(t_prefix+'a_2'), qa: true},
        {title: t(t_prefix+'q_3'), value: t(t_prefix+'a_3'), qa: true},
        {title: t(t_prefix+'q_4'), value: t(t_prefix+'a_4'), qa: true},
        {title: t(t_prefix+'q_5'), value: t(t_prefix+'a_5'), qa: true},
        {title: t(t_prefix+'q_6'), value: t(t_prefix+'a_6'), qa: true},
        {title: t(t_prefix+'q_7'), value: t(t_prefix+'a_7'), qa: true}
    ];

    return (
        <Layout breadcrumb={t(t_prefix+'side_title')}>
        <div className="qualityAndAssurance">
            <Container maxWidth="lg">
                    <FullWidthGrid>
                        <Grid item xs={12}>
                            <div className="signin-form-container">
                                <div className="qa-title">
                                    {t(t_prefix+'title')}
                                </div>
                                <div>
                                    {questionAnswers.map((obj) => {
                                        const {title, value, qa} = obj;
                                        return (
                                            <JobStatsContainer title={title} value={value} qa={qa} />
                                        )
                                    })}
                                </div>
                            </div>
                        </Grid>
                    </FullWidthGrid>
                </Container>
            </div>
        </Layout>
    )
}

export default QualityAndAssurance
