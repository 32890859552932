import React, {useState} from 'react';
import "./index.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const t_prefix = "upload_files.success_file."

const SuccessFile = ({details}) => {
    const {check_process, check_status, id, jobId, output, proof, signer, timestamp} = details
    const [display, setDisplay] = useState(false)
    const history = useHistory()
    const { t } = useTranslation();

    const handleFurtherDetail = () => {
        setDisplay(!display)
    }

    const handleDownloadFProofFile = (exportObj, exportName) =>{
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    const handleRedirect = (link) =>{
        window.location.href = link
    }

    const handleStatsRedirect = (link) =>{
        window.location.href = `https://tzstats.com/${link}`
    }

    return (
        <div className="success-file_c" >
            <div className="success-file_header"><CheckCircleIcon className="cancel-icon" />{t(t_prefix+'title')}</div>
            <div className="arrow-down_c">
                <div className="down-triangle" />
            </div>
            <div className="success-file_sub success-file_detail">
                <div className="sub_child">
                    {t(t_prefix+'new_detail_1')} <label className="text-bold">{details && timestamp} .</label>
                </div>

                {details &&
                <div className="further-detail_container">
                    <div className="each_detail" style={{display: "flex"}}>{t(t_prefix+'new_detail_2')}  <div className="text-bold">{details && proof && proof.file_name} .</div></div>
                    <div className="each_detail">{t(t_prefix+'new_detail_3')} <span className="text-over-flow text-bold">{details && proof && proof.hash_document}</span>.</div>
                    <div className="each_detail">{t(t_prefix+'new_detail_4')} <span className="text-bold">{details && check_process && check_process[0]}</span>.</div>
                    <div className="each_detail">{t(t_prefix+'new_detail_5')} <span className="text-bold stats" onClick={() => handleStatsRedirect(details && proof && proof.block_hash)}>{details && proof && proof.block_hash}</span>.</div>
                    <div className="each_detail">{t(t_prefix+'new_detail_6')} <span className="text-bold">{details && proof && proof.public_key}</span>.</div>
                </div>
                }

            </div>
        </div>
    )
}

export default SuccessFile


// <div className="success-file_c" >
//     <div className="success-file_header"><CheckCircleIcon className="cancel-icon" />{t(t_prefix+'title')}</div>
// <div className="arrow-down_c">
//     <div className="down-triangle" />
// </div>
// <div className="success-file_sub success-file_detail">
//     <div className="sub_child">
//         {t(t_prefix+'sub_title_1')} {details && signer} {t(t_prefix+'sub_title_2')} <label className="text-bold">{details && timestamp}</label>
//     </div>
//
//     {/* <div className="success-file_sub more-less_container">
//                     <label onClick={handleFurtherDetail}>{t(t_prefix+'more_details')} {display ? <ExpandLessIcon />:<ExpandMoreIcon />}</label>
//                 </div> */}
//
//     {details &&
//     <div className="further-detail_container">
//         <div className="each_detail" style={{display: "flex"}}>{t(t_prefix+'we_knew')} <div className="text-bold">{details && proof && proof.file_name}</div></div>
//         <div className="each_detail">
//             {t(t_prefix+'detail_1')} {details && proof && proof.algorithm} {t(t_prefix+'detail_2')} <span className="text-over-flow text-bold">{details && proof && proof.hash_document}</span>. {'\n'}
//             {t(t_prefix+'detail_3')} <span className="text-bold">{details && check_process && check_process[0]}</span>. {'\n'}
//             {t(t_prefix+'detail_4')} <span className="text-bold">{details && proof && proof.transaction_hash}</span>, {t(t_prefix+'detail_5')} <span className="text-bold">{details && proof && proof.block_hash}</span>, {t(t_prefix+'detail_6')} <span className="text-bold">{details && timestamp}</span>. {t(t_prefix+'detail_7')} <span className="text-bold">{details && proof && proof.public_key}</span>. {t(t_prefix+'detail_8')} <span className="text-bold">{details && signer}</span>.
//         </div>
//         <div className="each_detail">
//             {t(t_prefix+'detail_13')} <label onClick={() => handleDownloadFProofFile(details && proof, details && proof && proof.file_name)}>{t(t_prefix+'detail_9')}</label> {t(t_prefix+'detail_10')}.
//         </div>
//         <div className="each_detail">
//             {t(t_prefix+'detail_14')} <label onClick={() => handleRedirect(details && proof && proof.urls && proof.urls[0] && proof.urls[0].url)}>{t(t_prefix+'detail_11')}</label> {t(t_prefix+'detail_12')} <span className="text-bold">{t(t_prefix+'detail_12_a')}</span> ).
//         </div>
//     </div>
//     }
// </div>
// </div>
