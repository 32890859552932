import React, {useEffect, useState} from 'react';
import {TesozApi} from "../../api";
import { useParams, useHistory } from "react-router-dom";
import Loader from "../../components/spinner";
import Typography from "@material-ui/core/Typography";
import Modal from "../../components/modal";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Button from '@material-ui/core/Button';
import {useTranslation} from "react-i18next";
import "./index.scss";

const t_prefix = "job_detail."

const JobDetailContent = () => {
    const [detail, setDetail] = useState(null)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    // const [metaData, setMetaData] = useState(false)
    const history = useHistory()
    const params = useParams();
    const {t} = useTranslation()
    const api = new TesozApi()
    const { index } = params

    useEffect(() => {
        if(index){
            jobDetail(index)
        }
    }, [])

    const jobDetail = (index) => {
        setLoading(true)
        api.Job(index).then(response => {
            setLoading(false)
            if(response)
                if(response.status === 200){
                    if(response.data){
                        console.log("response.data", response.data)
                        const { id, flowName, createdDate, stateDate, state, callBackStatus,
                            docsNumber, channelName, blockHash, transactionHash, algorithm, rootHash, customFields} = response.data;
                        const jobDetail = {
                            id,
                            name: flowName,
                            createdDate,
                            statusDate: stateDate,
                            status: state,
                            callBackStatus,
                            docsNumber,
                            channelName: channelName || '',
                            blockHash,
                            transactionHash,
                            algorithm,
                            rootHash,
                            customFields: !!Object.keys(customFields).length ? customFields : false
                        }
                        setDetail(jobDetail)
                    }
                }
        }).catch(e => {
            console.log("error", e)
            setLoading(false)
        })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    console.log("detail is : ", detail)


    return (
        <div className="job-detail-content">
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Button className="btn btn-secondary signature-btns"  style={{fontSize: "18px", padding: "5px 20px"}} onClick={() => history.push("/dashboard")}>{t(t_prefix+'back_btn')}</Button>
            </div>
            <div className="job-detail-title">
                {t(t_prefix+'title')}
            </div>

            {loading &&
            <div style={{textAlign: "center"}}>
                <Loader />
            </div>}

            <Modal open={open} handleOpen={handleClickOpen} handleClose={handleClose}>
                <div className="header-title">
                    <label style={{width: "50%", textAlign: "center"}}><strong>{t(t_prefix+'meta_key')}</strong></label><label style={{width: "50%", textAlign: "center"}}><strong>{t(t_prefix+'meta_value')}</strong></label>
                    {/*<label>Meta Data</label><CloseIcon onClick={handleClose} />*/}
                </div>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {detail && detail.customFields && Object.keys(detail.customFields).map((key, index) => {
                            return  <div className="modal-container" key={index}><label style={{width: "50%", textAlign: "center"}}>{key}</label><label style={{width: "50%", textAlign: "center"}}>{detail.customFields[key]}</label></div>
                        })}
                    </Typography>
                </DialogContent>
            </Modal>

            {detail &&
                <>
                    <div className="job-detail-data">
                        <div className="job-detail-key"><strong>{t(t_prefix+'id')}</strong></div><div className="job-detail-value">{detail.id}</div>
                    </div>
                    <div className="job-detail-data">
                        <div  className="job-detail-key"><strong>{t(t_prefix+'name')}</strong></div><div className="job-detail-value">{detail.name}</div>
                    </div>
                    <div className="job-detail-data">
                        <div  className="job-detail-key"><strong>{t(t_prefix+'cr_date')}</strong></div><div className="job-detail-value">{detail.createdDate}</div>
                    </div>
                    <div className="job-detail-data">
                        <div  className="job-detail-key"><strong>{t(t_prefix+'st_date')}</strong></div><div className="job-detail-value">{detail.statusDate}</div>
                    </div>
                    <div className="job-detail-data">
                        <div  className="job-detail-key"><strong>{t(t_prefix+'status')}</strong></div><div className="job-detail-value">{detail.status}</div>
                    </div>
                    <div className="job-detail-data">
                        <div  className="job-detail-key"><strong>{t(t_prefix+'call_back_status')}</strong></div><div className="job-detail-value">{detail.callBackStatus}</div>
                    </div>
                    <div className="job-detail-data">
                        <div  className="job-detail-key"><strong>{t(t_prefix+'docs')}</strong></div><div className="job-detail-value">{detail.docsNumber}</div>
                    </div>
                    {detail.channelName &&
                    <div className="job-detail-data">
                        <div className="job-detail-key"><strong>{t(t_prefix+'channel')}</strong></div><div className="job-detail-value">{detail.channelName}</div>
                    </div>}
                    <div className="job-detail-data job-detail-hash">
                        <div  className="job-detail-key job-detail-root-key"><strong>{t(t_prefix+'block')}</strong></div><div className="job-detail-value">{detail.blockHash}</div>
                    </div>
                    <div className="job-detail-data job-detail-hash">
                        <div  className="job-detail-key job-detail-root-key"><strong>{t(t_prefix+'transaction')}</strong></div><div className="job-detail-value">{detail.transactionHash}</div>
                    </div>
                    <div className="job-detail-data">
                        <div  className="job-detail-key"><strong>{t(t_prefix+'hash_algo')}</strong></div><div className="job-detail-value">{detail.algorithm}</div>
                    </div>
                    <div className="job-detail-data job-detail-hash">
                        <div  className="job-detail-key job-detail-root-key"><strong>{t(t_prefix+'root_hash')}</strong></div><div className="job-detail-value">{detail.rootHash}</div>
                    </div>
                    {detail.customFields &&
                    <div className="job-detail-data">
                        <div  className="job-detail-key" style={{display: "flex", alignItems: "center"}}><strong>{t(t_prefix+'meta_data')}</strong></div><div className="job-detail-value">
                            <Button className="btn btn-secondary signature-btns" style={{fontSize: "20px", padding: "5px 20px"}} onClick={() => setOpen(true)}>{t(t_prefix+'meta_data_btn')}</Button>
                        </div>
                    </div>}

                    <div className="job-detail-data" />

                    {/*<div className="job-detail-data">*/}
                    {/*    <div  className="job-detail-key"><strong></strong></div><div className="job-detail-value"><button onClick={() => {}}><RotateLeftIcon /> Retry Signature</button></div>*/}
                    {/*</div>*/}
                </>
            }
        </div>
    )
}

export default JobDetailContent
