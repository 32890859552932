import React, {useEffect} from 'react';
import "./index.scss";

const Menu = ({language, profile, listItems, menuChange}) => {

    return (
        <div className="main-menu-container">

            <div className="arrow-container ">
                <div className={`${language || profile ? "arrow-up-black" :  "menu-display-none"}`}>
                    <div className="arrow-up-orange"></div>
                </div>
            </div>

            <div className={` ${language ? "generic-menu language-menu" : profile ? "generic-menu profile-menu" : "menu-display-none"}`} >
                {listItems.map((el) => {
                    const {name, to} = el;
                    return (
                        <div className="items" key={name} onClick={() => menuChange(to, name)}>{name}</div>
                    )
                })}
            </div>

       </div>
    )
}

export default Menu