import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import { Formik, Form, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import FullWidthGrid from '../../components/grid';
import { editUserAccount } from "../../utilities/validations";
import { countriesList } from "../../utilities/constants";
import "./index.scss";
import {useHistory, useParams} from "react-router-dom";
import { TesozApi } from "../../api";
import SimpleAlerts from "../../components/alerts";
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import * as Yup from "yup";

const t_prefix = "edit_user."

const EditManageUsersContent = () => {
    const [userInitialValues, setUserInitialValues] = useState(null)
    const [success, setSuccess] = useState("")
    const { userId } = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const api = new TesozApi()

    useEffect(() => {
        if(userId){
            getUserData()
        }
        else {
            history.push("/dashboard")
        }
    }, [])

    const editUserAccount = Yup.object().shape({
        fullName: Yup.string()
            .required(t(t_prefix+'required'))
            .min(2, t(t_prefix+'full_name_length_short'))
            .max(50, t(t_prefix+'full_name_length_long')),
        // lastName: Yup.string()
        //     .required('Required *')
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        login: Yup.string()
            .required(t(t_prefix+'required'))
            .min(2, t(t_prefix+'login_length_short'))
            .max(50, t(t_prefix+'login_length_long')),
        email: Yup.string().email(t(t_prefix+'invalid_email')).required(t(t_prefix+'required')),
        // company: Yup.string()
        //     .required('Required *')
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        // country: Yup.string()
        //     .required('Required *')
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        // profile: Yup.string()
        //     .required('Required *')
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        signatureLimit: Yup.number()
            .positive()
            .integer(),
        // tesozPublicKey: Yup.string()
        //     .required('Required *')
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        // tesozAccount: Yup.string()
        //     .required('Required *')
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        // password: Yup.string().required('Required *'),
        // passwordConfirmation: Yup.string().required('Required *').oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const getUserData = () => {
        api.GetAccount(userId).then(response => {
            console.log("response edit user", response)
            if(response){
                if(response.status === 200 && response.data) {
                    const { company, country, disabled, email, firstLogin, fullName, isAdmin, login, tezosAccountState} = response.data;
                    setUserInitialValues({
                        fullName: fullName,
                        login: login,
                        email: email,
                        company: company,
                        isAdmin: isAdmin,
                        country: country,
                        signatureLimit: '',
                        tesozPublicKey: tezosAccountState,
                        tesozAccount: tezosAccountState,
                    })
                }
            }
        }).catch(e => {
            console.log("e", e)
        })
    }

    const getModifiedValues = (values, initialValues, resetForm) => {
        let modifiedValues = {};

        if (values) {
            Object.entries(values).forEach((entry) => {
                let key = entry[0];
                let value = entry[1];

                if (value !== initialValues[key]) {
                    modifiedValues[key] = value;
                }
            });
        }

        return handleSubmit(modifiedValues, resetForm);
    };

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    const handleSubmit = async (values, resetForm) => {

        // await sleep(500);
        // alert(JSON.stringify(values, null, 2));
        // resetForm()

        EditUser(userId, values)
        console.log("values edit profile", values)
    }


    const EditUser = (id, data) => {
        console.log("id : " + id + " : data : ", data)
        // alert(JSON.stringify(data, null, 2));
        if(id){
         api.EditAccount(id, data).then(response => {
             if(response){
                 // debugger
                 if(response.status === 200 && response.data){
                     const { company, country, email, firstLogin, fullName, isAdmin, login, tezosAccountState} = response.data;
                     setUserInitialValues({
                         fullName: fullName,
                         login: login,
                         email: email,
                         isAdmin: isAdmin,
                         company: company,
                         country: country,
                         signatureLimit: '',
                         tesozPublicKey: tezosAccountState,
                         tesozAccount: tezosAccountState,
                     })
                     setSuccess("Information updated successfully")
                 }
             }
         }).catch(e => {
             console.log("e", e)
         })
        }
    }

    const handleReset = (resetForm) => {
        // setIsModified(true)
        resetForm()
    }

    console.log("user", userInitialValues)

    return (
        <div className="manage-users-edit">
            <div className="title">
                Edit User
            </div>


            {success && <SimpleAlerts type="success" message={success} />}

            <div>
                <Formik
                    initialValues={userInitialValues}

                    // isInitialValid={() => setIsModified(true)}

                    validationSchema={editUserAccount}

                    enableReinitialize={true}

                    onSubmit={(values, {resetForm}) => getModifiedValues(values, userInitialValues, resetForm)}
                >
                    {({ values, errors, touched, resetForm, isValid, dirty }) => (
                        <Form className="edit-account-form" style={{marginTop: "20px"}}>
                            {console.log("isValid : ", isValid + " : dirty ; ", dirty)}
                            {console.log("values && values.isAdmin", values && values.isAdmin)}
                            <FullWidthGrid>
                                <Grid item sm={12} lg={6}>
                                    <label className="edit-acc-label">{t(t_prefix+'login_label')} *</label>
                                    <Field name="login" placeholder={t(t_prefix+'login_placeholder')}  className={`edit-acc-text-field ${(!errors.login || !touched.login) ? 'edit-margin-btm' : ''}`}  />
                                    {errors.login && touched.login ? (
                                        <div className="mgn-btm">{errors.login}</div>
                                    ) : null}
                                </Grid>

                                <Grid item sm={12} lg={6}>
                                    <label className="edit-acc-label">{t(t_prefix+'email_label')}</label>
                                    <Field name="email" type="email" placeholder={t(t_prefix+'email_placeholder')} className={`edit-acc-text-field ${(!errors.email || !touched.email) ? 'edit-margin-btm' : ''}`}  />
                                    {errors.email && touched.email ? <div className="mgn-btm">{errors.email}</div> : null}
                                </Grid>

                                <Grid item sm={12} lg={6}>
                                    <label className="edit-acc-label">{t(t_prefix+'company_label')}</label>
                                    <Field name="company" placeholder={t(t_prefix+'company_placeholder')} className={`edit-acc-text-field ${(!errors.company || !touched.company) ? 'edit-margin-btm' : ''}`}  />
                                    {errors.company && touched.company ? (
                                        <div className="mgn-btm">{errors.company}</div>
                                    ) : null}
                                </Grid>

                                <Grid item sm={12} lg={6}>
                                    <label className="edit-acc-label">{t(t_prefix+'country_label')}</label>
                                    <Field name="country" placeholder={t(t_prefix+'country_placeholder')} disabled={values && values.isAdmin} className={`edit-acc-text-field ${(!errors.country || !touched.country) ? 'edit-margin-btm' : ''}`}  />
                                    {errors.country && touched.country ? (
                                        <div className="mgn-btm">{errors.country}</div>
                                    ) : null}
                                </Grid>

                                <Grid item sm={12}>
                                    <div>
                                        <label className="edit-acc-label">{t(t_prefix+'admin_label')}</label>
                                    </div>
                                    <Field type="checkbox" name="isAdmin" placeholder="Your Profile" className={`add-checkbox-field ${(!errors.isAdmin || !touched.isAdmin) ? 'edit-margin-btm' : ''}`}  />
                                    {errors.isAdmin && touched.isAdmin ? (
                                        <div className="mgn-btm">{errors.isAdmin}</div>
                                    ) : null}
                                </Grid>

                                <Grid item sm={12}>
                                    <label className="edit-acc-label">{t(t_prefix+'sign_limit_label')}</label>
                                    <Field type="number" name="signatureLimit" placeholder={t(t_prefix+'sign_limit_placeholder')}  className={`edit-acc-text-field ${(!errors.signatureLimit || !touched.signatureLimit) ? 'edit-margin-btm' : ''}`} />
                                    {errors.signatureLimit && touched.signatureLimit ? (
                                        <div className="mgn-btm">{errors.signatureLimit}</div>
                                    ) : null}
                                </Grid>

                                {/*<Grid item sm={12}>*/}
                                {/*    <div>*/}
                                {/*        <label className="edit-acc-label">Use keys in configuration</label>*/}
                                {/*    </div>*/}
                                {/*    <Field type="checkbox" name="useKeysInConfig" placeholder="Signature Limit" className={`add-checkbox-field ${(!errors.useKeysInConfig || !touched.useKeysInConfig) ? 'edit-margin-btm' : ''}`}  />*/}
                                {/*    {errors.useKeysInConfig && touched.useKeysInConfig ? (*/}
                                {/*        <div className="mgn-btm">{errors.useKeysInConfig}</div>*/}
                                {/*    ) : null}*/}
                                {/*</Grid>*/}



                                {/*{!values.useKeysInConfig &&*/}
                                {/*<>*/}
                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'tesoz_public_key_label')}</label>
                                        <Field name="tesozPublicKey" placeholder={t(t_prefix+'tesoz_public_key_placeholder')}  className={`edit-acc-text-field ${(!errors.tesozPublicKey || !touched.tesozPublicKey) ? 'edit-margin-btm' : ''}`} />
                                        {errors.tesozPublicKey && touched.tesozPublicKey ? (
                                            <div className="mgn-btm">{errors.tesozPublicKey}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'tesoz_private_key_label')}</label>
                                        <Field name="tesozAccount" placeholder={t(t_prefix+'tesoz_private_key_placeholder')}  className={`edit-acc-text-field ${(!errors.tesozPrivateKey || !touched.tesozPrivateKey) ? 'edit-margin-btm' : ''}`}  />
                                        {errors.tesozPrivateKey && touched.tesozPrivateKey ? (
                                            <div className="mgn-btm">{errors.tesozPrivateKey}</div>
                                        ) : null}
                                    </Grid>
                                {/*</>}*/}

                                <Grid item sm={12}>
                                    <label className="edit-acc-label">{t(t_prefix+'full_name_label')} *</label>
                                    <Field name="fullName" placeholder={t(t_prefix+'full_name_placeholder')} className={`edit-acc-text-field ${(!errors.fullName || !touched.fullName) ? 'edit-margin-btm' : ''}`}  />
                                    {errors.fullName && touched.fullName ? (
                                        <div className="mgn-btm">{errors.fullName}</div>
                                    ) : null}
                                </Grid>

                            </FullWidthGrid>


                            <div className="sign-btns-container" >
                                <Button className="btn btn-secondary-red btn-lg fifty-percent-rounded signature-btns signature-margin-right" onClick={() => handleReset(resetForm)} >
                                    {t(t_prefix+'cancel_btn')}
                                </Button>
                                <Button className="btn btn-secondary btn-lg fifty-percent-rounded signature-btns" type="submit" >
                                    {t(t_prefix+'save_btn')}
                                </Button>
                            </div>
                            {/*disabled={isValid}*/}

                        </Form>
                    )}
                </Formik>
            </div>

        </div>
    )
}

export default EditManageUsersContent
