import React, {useState} from 'react'
import MenuIcon from '@material-ui/icons/Menu';
import ToggleSidebar from "../toggleSidebar";
import LanguageAndMenu from "../languageAndMenu";
import "./index.scss";

const Header = () => {
    const [openSidebar, setOpenSidebar] = useState(false)

    const toggleSidebarOpen = () => {
        console.log("openSidebar called")
        setOpenSidebar(true)
    }

    const toggleSidebarClose = () => {
          setOpenSidebar(false)
    }

    return (
        <>
            <div className="header">
                <div className="header-first-container">
                    <div className="header-second-container">
                        <img alt="new trimmed logo" src={window.location.origin + '/img/trimmed-new-logo.png'} className="latest-trimmed-logo headerLogo" />

                        {/* <div onClick={toggleSidebarOpen} > */}
                            <MenuIcon className="menu-icon-header"  onClick={toggleSidebarOpen}/>
                        {/* </div> */}
                        <div className="language-name_container">
                            <LanguageAndMenu />
                        </div>
                        {/* <Menu /> */}
                    </div>
                </div>
            </div>
            <ToggleSidebar open={openSidebar} toggleSidebarClose={toggleSidebarClose} />
        </>
    )
}

export default Header
