export function TableDateTimeField ({dateTime})  {

    let now = dateTime.split(' ');

    return (
        <label>
            <span style={{color: "#707070"}}>{now[0]}</span>
            <span style={{color: "#B8DFD8", paddingLeft: "6px"}}>{now[1]} {now[2]}</span>
        </label>
    )
}

export function Actions ({actions, handleJobDetail})  {
    console.log("actions", actions)
    console.log("actions.key", actions.key)
    console.log("handleJobDetail", handleJobDetail)

    return (
        <img src={window.location.origin + '/img/table-add-icon.svg'} className="image-hover" onClick={() => handleJobDetail(actions.key)}/>
    )
}
