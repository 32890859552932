import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FullWidthGrid from '../../grid';
import './index.scss'

const AboutUs = ({title, aboutUs, description, contactUs}) => {
    console.log("title, aboutUs, description", title, aboutUs, description)
    return (
        <div className="about">
            <FullWidthGrid>
            <Grid item xs={12} md={4} >
                <div className="left-title">
                    {aboutUs}
                </div>
            </Grid>
            <Grid item xs={12} md={8} >
                <div className="right-title">
                    {title}
                </div>
                <div className="right-line">

                </div>
                <div className="right-description">
                    {description}
                </div>
                {contactUs && 
                    <div className="about-contact-us-btn">
                        <Button className="btn btn-primary btn-lg contct-us-rounded contct-us-padding contact-us-btn max-height-contact-us" type="submit">
                            {contactUs}
                        </Button>
                    </div>}
            </Grid>    
        </FullWidthGrid>
        </div>
    )
}

export default AboutUs