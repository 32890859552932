import React from 'react';
import CheckFileError from "../../components/checkFilesError";
import { useTranslation } from 'react-i18next';
import "./index.scss";

const t_prefix_first = "upload_files.detail_generic.first."
const t_prefix_second = "upload_files.detail_generic.second."
const t_prefix_third = "upload_files.detail_generic.third."

const t_prefix_doc__hash_not_found = "upload_files.error_file_doc_not_found."

const CheckFilesError = ({detailData, firstElementNone}) => {
    console.log("detailData", detailData)
    console.log("firstElementNone", firstElementNone)
    const { t } = useTranslation();

    const errorData = [
        {
            title: t(t_prefix_doc__hash_not_found+'title'),
            detail: [{data: t(t_prefix_doc__hash_not_found+'detail')}],
            icon: true,
            successIcon: false,
        },
        {
            title: t(t_prefix_second+'title'),
            detail: [
                {data: t(t_prefix_second+'detail_1')},
                {data: t(t_prefix_second+'detail_2')},
                {data: t(t_prefix_second+'detail_3')},
                {data: t(t_prefix_second+'detail_4')}
            ],
            icon: false,
            successIcon: false,
        },
        {
            title: t(t_prefix_third+'title'),
            detail: [
                {data: t(t_prefix_third+'detail_1')},
                {data: t(t_prefix_third+'detail_2')},
                {data: t(t_prefix_third+'detail_3')},
                {data: t(t_prefix_third+'detail_4')},
                {data: t(t_prefix_third+'detail_5')},
                {data: t(t_prefix_third+'detail_6')},
                {data: t(t_prefix_third+'detail_7')}
            ],
            icon: false,
            successIcon: false,
        }
]

    return (
        <div className="check-files-container" >
            {firstElementNone ? null :
            <CheckFileError title={detailData ? detailData.title : errorData[0].title}
                            detail={detailData ? detailData.detail : errorData[0].detail}
                            icon={detailData ? detailData.icon : errorData[0].icon}
                            successIcon={detailData ? detailData.successIcon : errorData[0].successIcon}
                            furtherDetail={detailData ? detailData.furtherDetail: null}
            />}
            {/*<div className="for-understanding">*/}
            {/*    {t(t_prefix_first+'title')}*/}
            {/*</div>*/}
            {/*<div className="fifty-percent-wrapper" >*/}
            {/*    <div className="fifty-percent-width" >*/}
            {/*        <CheckFileError title={errorData[1].title} detail={errorData[1].detail} icon={errorData[1].icon} successIcon={errorData[1].successIcon} />*/}
            {/*    </div>*/}
            {/*    <div className="fifty-percent-width">*/}
            {/*        <CheckFileError title={errorData[2].title} detail={errorData[2].detail} icon={errorData[2].icon} successIcon={errorData[2].successIcon} />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default CheckFilesError
