import React from 'react';
import Layout from '../components/layout';
import Container from '@material-ui/core/Container';
import FullWidthGrid from '../components/grid';
import Grid from '@material-ui/core/Grid';
import AboutUs from "../content/aboutUs"
import {useTranslation} from "react-i18next";

const t_prefix = "upload_files.about_us."

const About = () => {
    const { t } = useTranslation();

    return (
        <Layout breadcrumb={t(t_prefix+'side_title')}>
            <div className="qa">
                <Container maxWidth="lg">
                    <FullWidthGrid>
                        <Grid item xs={12}>
                            <div className="upload-file-container">
                                <AboutUs />
                            </div>
                        </Grid>
                    </FullWidthGrid>
                </Container>
            </div>
        </Layout>
    )
}

export default About
