import React from 'react';
import FullWidthGrid from '../../grid';
import Grid from '@material-ui/core/Grid';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { contactUsSchema } from "../../../utilities/validations" 
import './index.scss'


const ContactUs = () => {
    
    const handleSubmit = (values) => {
        console.log("values contact us", values)
    }

    return (
        <div className="contact-us-container">
            {/* <FullWidthGrid>
                <Grid item xs={12}> */}
                    <div className="contact-us-title">
                        We'd love to hear from you
                    </div>
                {/* </Grid>
                <Grid item xs={12}> */}
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            phoneNo: '',
                            textarea: ''
                        }}

                        validationSchema={contactUsSchema}

                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form className="contact-us-form">

                                <Field name="name" placeholder="Your Name" className={`contact-us-text-fields contact-input ${(!errors.name || !touched.name) ? 'mgn-btm' : ''}`} />
                                {errors.name && touched.name ? (
                                    <div className="mgn-btm">{errors.name}</div>
                                ) : null}

                                <Field name="email" type="email" placeholder="Your Email Address" className={`contact-us-text-fields contact-input ${(!errors.email || !touched.email) ? 'mgn-btm' : ''}`} />
                                {errors.email && touched.email ? <div className="mgn-btm">{errors.email}</div> : null}

                                <Field name="phoneNo" placeholder="Your Phone No" className={`contact-us-text-fields contact-input ${(!errors.phoneNo || !touched.phoneNo) ? 'mgn-btm' : ''}`} />
                                {errors.phoneNo && touched.phoneNo ? (
                                    <div className="mgn-btm">{errors.phoneNo}</div>
                                ) : null}

                                <Field name="textarea" as="textarea" placeholder="How can we help" className={`contact-us-text-fields contact-text-area ${(!errors.textarea || !touched.textarea) ? 'mgn-btm' : ''}`} />
                                {errors.textarea && touched.textarea ? (
                                    <div className="mgn-btm">{errors.textarea}</div>
                                ) : null}
                           
                                <div className="btn-margin">
                                    <Button className="btn btn-primary btn-lg contct-us-rounded contct-us-padding contact-us-btn btn-max-height" type="submit">
                                        Send Us a Message
                                    </Button>
                                </div>

                            </Form>
                        )}
                    </Formik>
                {/* </Grid>
            </FullWidthGrid> */}
        </div>
    )
}

export default ContactUs