import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Signin from "./Pages/signin"
import ForgotPassword from './Pages/forgotpassword';
import Dashboard from './Pages/dashboard';
import Home from './Pages/home';
import QualityAndAssurance from './Pages/qualityAndAssurance';
import Jobs from './Pages/jobs';
import JobDetail from "./Pages/jobDetail"
import ManageUsers from './Pages/manageUsers';
import AddNewUser from "./Pages/addNewUser";
import SignatureRequest from './Pages/signatureRequest';
import DocumentList from './Pages/documentList'
import About from './Pages/about';
import UploadFiles from "./Pages/uploadFiles";
import ChannelManagement from './Pages/channelManagement';
import MyAccount from './Pages/myAccount';
import DeferredDocsPending from './Pages/deferredDocsPending';
import { getItem } from "./utilities/localStorage";
import EditManageUsers from "./Pages/editManageUsers";
import CheckFilesCertiff from "./Pages/checkFiles";

const AuthRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={
               (props) => {
                   if (getItem('token')) {
                       return ( <Component {...props} />)
                   } else {
                       return <Redirect to='/login'/>
                   }
               }
           }
    />
);

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={UploadFiles} />
                <Route path="/login" component={Signin} />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route path="/home" component={Home} />
                <Route path="/qa" component={QualityAndAssurance} />
                <Route path="/about" component={About} />
                <Route path="/uploadFiles" component={UploadFiles} />
                <Route path="/checkFiles" component={CheckFilesCertiff} />
                <AuthRoute path="/dashboard" component={Dashboard} />
                <AuthRoute path="/jobs" component={Jobs} />
                <AuthRoute path="/job/detail/:index" component={JobDetail} />
                {/*<AuthRoute path="/documentlist/:index" component={DocumentList} />*/}
                <AuthRoute path="/documentlist" component={DocumentList} />
                <AuthRoute path="/deferreddocspending" component={DeferredDocsPending} />
                <AuthRoute path="/signaturerequest" component={SignatureRequest} />
                <AuthRoute path="/manageusers" component={ManageUsers} />
                <AuthRoute path="/edit/manageusers/:userId" component={EditManageUsers} />
                <AuthRoute path="/addNewUser" component={AddNewUser} />
                <AuthRoute path="/myaccount" component={MyAccount} />
                <AuthRoute path="/channelmanagement" component={ChannelManagement} />
            </Switch>
        </Router>
    )
}

export default Routes
