import React, {Fragment} from 'react';
import "./index.scss";

const Stepper = ({total, stepper, step, changeStep, error}) => {
    const items = stepper.length;
    return (
        <div className="grid--stepper-wrapper">
            <div className={` grid-stepper ${total === 2 ? "two-steps" : "three-steps"}`}>

                {stepper.map((el, i) => {

                    const notLastItem = items !== i + 1 ? true : false;

                    return (
                        <Fragment key={i}>
                            <div className={` circle ${step === i ? "circle-active" : "circle-inactive"}`} onClick={() => changeStep(i)} />
                            {notLastItem && <div className="line"  />}
                        </Fragment>
                    )
                })}

            </div>
            <div className="stepper-text-container" >

                {stepper.map((el, i) => {
                    const {name, id} = el;
                    return (
                            <div className={` circle-text ${step === i ? error ? "circle-active-error-text" : "circle-active-text" : "circle-inactive-text"}`} key={id} onClick={() => changeStep(i)}>{name}</div>
                    )
                })}

            </div>
        </div>
    )
}

export default Stepper
