import React, {useEffect, useState} from 'react';
import Stepper from "../../components/stepper";
import DirectSignature from "../../content/directSignature";
import DeferredSignature from "../../content/deferredSignature";
import {TesozApi} from "../../api";
import "./index.scss";
import {useTranslation} from "react-i18next";

const t_prefix = "signature_request."

const SignatureRequestContent  = () => {
    const [step , setStep] = useState(0);
    const [checkFileError , setCheckFileError] = useState(false);
    const {t} = useTranslation();
    const api = new TesozApi()

    const stepper = [
        {name: t(t_prefix+'direct_sign_step'), id : 0},
        {name: t(t_prefix+'deferred_sign_step'), id : 1},
    ]

    useEffect(() => {
        whoAmI()
    }, [])

    const whoAmI = () => {
        api.Me().then(response => {
            console.log("response", response)
            if(response){
                if(response.status === 200 && response.data){
                    const {data} = response;
                    if(data){
                        const { id } = data;
                        getJobsCount(id)
                    }
                }
            }
        }).catch(e => {
            console.log("e", e)
        })
    }

    const getJobsCount = (id) => {
        const params = {
            accountId: id
        }
        // debugger
        api.JobsCount(params).then(response => {
            console.log("response", response)
        }).catch(e => {
            console.log("e", e)
        })
    }

    const handleChangeStep = (index) => {
        setStep(index)
    }

    return (
        <div className="sign-req-main">
             <div className="sign-req-inner">
                 <div className="stepper-container" >
                    <Stepper total={2} stepper={stepper} step={step} error={checkFileError} changeStep={handleChangeStep} />
                 </div>
             </div>
             {step === 0 && <DirectSignature />}
             {step === 1 && <DeferredSignature />}

        </div>
    )
}

export default SignatureRequestContent
