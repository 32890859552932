import React from 'react';
import FullWidthGrid from '../../grid';
import Grid from '@material-ui/core/Grid';
import './index.scss'

const LetsConnect = () => {
    return (
        <div className="lets-connect-mainContainer">
            <FullWidthGrid>
                <Grid item xs={12} md={4}>
                    <div className="connect-title" >
                        Lets Connect
                    </div>
                </Grid>
                <Grid item xs={12} md={8}>
                    <div className="icons-wrapper">
                        <div className="icon-container">
                            <img alt="twitter" src={window.location.origin + '/img/twitter.svg'} className="" />
                        </div>
                        <div className="icon-container">
                            <img alt="git hub" src={window.location.origin + '/img/github.svg'} className="" />
                        </div>
                        <div className="icon-container">
                            <img alt="face book" src={window.location.origin + '/img/facebook.svg'} className="" />
                        </div>
                        <div className="icon-container">
                            <img alt="behance" src={window.location.origin + '/img/behance.svg'} className="" />
                        </div>
                        <div className="icon-container">
                            <img alt="instagram" src={window.location.origin + '/img/instagram.svg'} className="" />
                        </div>
                        <div className="icon-container">
                            <img alt="other instagram" src={window.location.origin + '/img/instagram.svg'} className="" />
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="title-icon-wrapper"></div>
                </Grid>

                <Grid item xs={12}>
                    <div className="city">
                        Gulberg 3, Lahore, Pakistan.
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="ph-no">
                        +92 300 0851852
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="email">
                        info@hafeezstore.com
                    </div>
                </Grid>
            </FullWidthGrid>
        </div>
    )
}

export default LetsConnect
