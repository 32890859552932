import React from 'react';
import FullWidthGrid from '../../grid';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import "./index.scss"

const HomeMain = () => {
    const history = useHistory()
    console.log("history")
    return (
        <div className="homeMain">
            <FullWidthGrid>
                <Grid item xs={12} sm={6}>
                    <div className="image-wrapper">
                        <img alt="home bg" src={window.location.origin + '/img/homebackgroud.svg'} className="signin-logo-size" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div style={{height: "100%", display: "flex", flexDirection: 'column', justifyContent: "center"}}>
                        <div style={{fontSize: "50px"}}>
                            <span className="home-color-title-dark">The proof system for</span> <span className="home-color-title-light">modern companies</span>
                        </div>
                        <p className="home-color-desc-dark" style={{fontSize: "25px", lineHeight: "1.5"}}>
                            Tesoz makes you able to create your jobs and add your documents to be signed. You will also be able to check the authenticity of your documents.
                        </p>
                        <div className="fifty-percent-width" style={{marginTop: "40px"}}>
                            <Button className="btn btn-primary btn-lg fifty-percent-rounded" fullWidth onClick={() => history.push("/")} >
                                Login Now
                            </Button>
                        </div>
                    </div>
                </Grid>
            </FullWidthGrid>
        </div>


    )
}

export default HomeMain
