import React from 'react';
import FullWidthGrid from '../../grid';
import AboutUs from "../aboutUs";
import OurClients from '../ourClients';
import Grid from '@material-ui/core/Grid';
import './index.scss';

const displayData = [
    {
        aboutUs: 'SECURING DATA WORLDWIDE',
        title: 'Democratizing access to Digital Truth',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ut lorem vitae enim tincidunt rhoncus in vel urna. Vestibulum fringilla mi quis mi porta ultricies. Donec facilisis in nunc vitae malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dignissim pharetra metus, non tincidunt lorem mattis sit amet.'
    },
    {
        aboutUs: '',
        title: 'Our Clients',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ut lorem vitae enim tincidunt rhoncus in vel urna. Vestibulum fringilla mi quis mi porta ultricies. Donec facilisis in nunc vitae malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dignissim pharetra metus, non tincidunt lorem mattis sit amet.'
    },
    {
        aboutUs: 'CONTACT US',
        title: 'Lets work together',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ut lorem vitae enim tincidunt rhoncus in vel urna. Vestibulum fringilla mi quis mi porta ultricies. Donec facilisis in nunc vitae malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dignissim pharetra metus, non tincidunt lorem mattis sit amet.',
        contactUs: "Contact Us"
    }
]

const clients = [
    {path: window.location.origin + '/img/nomadHomeLarge.png'},
    {path: window.location.origin + '/img/pelicanHomeLarge.png'},
    {path: window.location.origin + '/img/northFaceHomeLarge.png'},
    {path: window.location.origin + '/img/sixBaseHomeLarge.png'},
    {path: window.location.origin + '/img/sixBaseHomeLarge.png'},
    {path: window.location.origin + '/img/northFaceHomeLarge.png'},
    {path: window.location.origin + '/img/pelicanHomeLarge.png'},
    {path: window.location.origin + '/img/nomadHomeLarge.png'}
]


const HomeDetail = () => {
    const [firstData, secondData, thirdData] = displayData;
    console.log("firstData", firstData)

    return (
        <div>
                <AboutUs {...firstData} />
                <AboutUs {...secondData} />
                <FullWidthGrid>
                    {clients.map((client) => {
                        return <OurClients path={client.path} />})
                    }
                </FullWidthGrid>
                <AboutUs {...thirdData} />
        </div>
    )
}

export default HomeDetail