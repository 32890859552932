import React from 'react'
import Grid from '@material-ui/core/Grid';
import "./index.scss"

const OurClients = ({ path }) =>
        <Grid xs={12} md={6} lg={3} className= "our-clients">
            <img alt="" src={path} className="clients-images" />
        </Grid>

export default OurClients
