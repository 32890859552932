import React from 'react';
import 'antd/dist/antd.css';
import './index.scss';
import { Table, Tag, Space } from 'antd';
import { HourglassOutlined } from '@ant-design/icons';



const AntdTable = ({columns, data, loading}) => {

    const tableLoading = {
        spinning: loading,
        indicator: <HourglassOutlined spin />,
    }

    return (
            <Table columns={columns} dataSource={data} loading={tableLoading} />
    )
}

export default AntdTable
