import React from 'react';
import "./index.scss";

const ContactUsLink = () => {
    return (
        <div className="contact-link">
            <h1 className="us-link">
                TEZOSDIGISIGN@sword-group.com
                {/*<a className="menu" href=""><span>TEZOSDIGISIGN@sword-group.com</span></a>*/}
            </h1>
        </div>
    )
}

export default ContactUsLink
