import React, {useState, useEffect} from 'react';
import Layout from '../components/layout';
import Container from '@material-ui/core/Container';
import FullWidthGrid from '../components/grid';
import Grid from '@material-ui/core/Grid';
import Stepper from "../components/stepper";
import CheckFiles from "../content/checkFiles";
import CheckFilesError from "../content/checkFilesError";
import ContactUsLink from "../content/contactLink";
import AboutUs from "../content/aboutUs";
import { useTranslation } from 'react-i18next';
import { TesozApi } from '../api';
import 'antd/dist/antd.css';
import { Skeleton, Space, Divider, Switch, Form, Radio } from 'antd';

const t_prefix = "upload_files.stepper."

const CheckFilesCertiff = () => {
    const [step , setStep] = useState(0);
    const [checkFileError , setCheckFileError] = useState(false);
    const [balifLogo, setBalifLogo] = useState(null)
    const [avatarShape, setAvatarShape] = useState('circle')
    const [active, setActive] = useState('false')
    const [size, setSize] = useState('Large')
    const { t } = useTranslation();
    const api = new TesozApi()

    useEffect(() => {
        getUserLogo()
    }, [])

    const getUserLogo = () => {
        let subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
        api.CertiffUserGet(subdomain).then(response => {
            console.log("response", response)
            if(response.status === 200){
                if(response.data.status === 'success'){
                    if(response.data.bailiff_logo){
                        setBalifLogo(response.data.bailiff_logo)
                    }
                }
                else if(response.data.status === 'error'){
                    if(response.data.message){
                        setBalifLogo(null)
                    }
                }

            }
        }).catch(e => setBalifLogo(null))
    }

    const stepper = [
        {name: t(t_prefix+'stepper_1'), id : 0}
    ]

    const setError = () => {
        setCheckFileError(true)
    }

    const handleChangeStep = (index) => {
            setStep(index)
    }

    let renderedComponent;

    if(step === 0){
        if(checkFileError){
            renderedComponent = <CheckFilesError />
        }
        else {
            renderedComponent = <CheckFiles displayError={setError} />
        }
    }

    if(step === 1) {
        renderedComponent = <div style={{paddingTop: "100px"}}><AboutUs /></div>
    }

    if(step === 2){
        renderedComponent = <ContactUsLink />
    }

    return (
        <div style={{position: "relative"}}>
            <div className="certiff-header">
                <Container maxWidth="lg">

                   <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <div>
                            <a href="https://ceertif.com/" className="custom-logo-link" rel="home" itemProp="url">
                                <img src="https://ceertif.com/wp-content/uploads/2020/12/Logo-test-ceertif-231x87.png"
                                    className="certif-logo custom-logo"
                                    alt="Eric Albou Huissier de Justice, Carolle Yana, Kevin Mimoun"
                                    itemProp="logo"
                                    srcSet="https://ceertif.com/wp-content/uploads/2020/12/Logo-test-ceertif-231x87.png 328w, https://blockchainhuissieray.com/wp-content/uploads/2018/05/iN_PROFILE_PIC-300x183.png 300w"/>
                            </a>
                        </div>

                        <div className="link-certiff-container">
                            <label className="link"><a className="link" href="https://ceertif.com/">ACCUEIL</a></label>
                            <label className="link link-pad-left"><a className="link" href="https://app.ceertif.com/login.php">ACCES CLIÉNT</a></label>
                        </div>
                   </div>

                    
                </Container>

            </div>

            <div className="certiff-hero_banner">
                <h1>
                     votre fichier déposé sur la blockchain
                </h1>
            </div>

            <div className="current-user_logo-container">
                {balifLogo ? <img src={balifLogo} className="current-user_logo" /> : <Skeleton.Image />}
            </div>

            <div className="qa">
                <Container maxWidth="lg">
                        <FullWidthGrid>
                            <Grid item xs={12}>
                                {/* <div className="upload-file-container"> */}
                                   <div className="upload-file-inner-container">
                                        <div className="upload-file-inner-wrapper-container certiff-container">
                                            {/*<Stepper stepper={stepper} step={step} error={checkFileError} changeStep={handleChangeStep} />*/}
                                            {/*<h1 style={{textUnderlineOffset: "10px", textDecoration: "underline", paddingTop: "10px", paddingBottom: "30px", color: "#858589", textAlign: "center"}}>{t(t_prefix+'stepper_1')}</h1>*/}
                                            {renderedComponent}
                                        </div>
                                   </div>
                                {/* </div> */}
                            </Grid>
                        </FullWidthGrid>
                </Container>
            </div>

            <div className="certif-footer">
                <p class="footer-credits-text text-center">Copyright © 2021<a href="https://ceertif.com/">Ceertif</a><span class="sep"> | </span>Developed By : <a href="https://hexasoft.io/">Hexasoft</a>
                </p>
            </div>
        </div>
    )
}

export default CheckFilesCertiff
