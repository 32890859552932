import React from 'react';
import Layout from '../components/layout';
import Container from '@material-ui/core/Container';
import FullWidthGrid from '../components/grid';
import Grid from '@material-ui/core/Grid';
import MyAccountContent from "../content/myAccount";
import {useTranslation} from "react-i18next";

const t_prefix = "my_account."

const MyAccount = () => {
    const {t} = useTranslation()

    return (
        <Layout breadcrumb={t(t_prefix+'side_title')}>
            <div className="qa">
                <Container maxWidth="lg">
                    <FullWidthGrid>
                        <Grid item xs={12}>
                            <div className="signin-form-container">
                                <MyAccountContent />
                            </div>
                        </Grid>
                    </FullWidthGrid>
                </Container>
            </div>
        </Layout>
    )
}

export default MyAccount
