import React, {useState} from 'react';
import FileUploadDropzone from "../../components/FileUpload";
import AntdTable from "../../components/Tables/antd-table";
import Button from '@material-ui/core/Button';
import "./index.scss";
import {useTranslation} from "react-i18next";


const t_prefix = "signature_request."

const DeferredSignature = () => {
    const [file, setFile] = useState([]);
    const {t} = useTranslation();

    const uploadFileText = {
        mandatory: t(t_prefix+'def_choose_file_upload'),
    }

    const columns = [
        {
            title: t(t_prefix+'col_def_file_name'),
            dataIndex: 'filename',
            key: 'filename',
            render: text => <label style={{color: "#707070"}}>{text}</label>
        },
        {
            title: t(t_prefix+'col_def_size'),
            dataIndex: 'size',
            key: 'size',
            render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
            title: t(t_prefix+'col_def_hash'),
            dataIndex: 'hash',
            key: 'hash',
            render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
            title: t(t_prefix+'col_def_key_value'),
            key: 'keysandvalues',
            dataIndex: 'keysandvalues',
            render: data => (
                <>
                    {data.map(el => {
                        const {type, value} = el;
                        return (
                            <>
                                {type === 'file-batch' && <label className="file-badge">{value}</label>}
                                {type === 'delete' && <img alt="bin" className="delete-badge" src={window.location.origin + '/img/bin.svg'} />}
                                {type === 'add' && <label className="add-badge">{value}</label>}
                            </>
                        )
                    })}
                </>
            )
        }
    ];

    const data = [
        {
            key: '1',
            filename: 'Arsahd_resume.pdf',
            size: '117KB',
            hash: 'hjhjhhjhjhjh',
            keysandvalues: [
                {type: "file-batch", value: "file_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "delete", value: "Delete"},
                {type: "add", value: t(t_prefix+'col_def_key_value_add_btn')}
            ]
        },
        {
            key: '2',
            filename: 'Maheen_cv.pdf',
            size: '123KB',
            hash: 'hjyhjyhjyhjy',
            keysandvalues: [
                {type: "file-batch", value: "file_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "delete", value: "Delete"},
                {type: "add", value: t(t_prefix+'col_def_key_value_add_btn')}
            ]
        },
        {
            key: '3',
            filename: 'John_cv.pdf',
            size: '122KB',
            hash: 'hjhjhhjhjhjhyui',
            keysandvalues: [
                {type: "file-batch", value: "file_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "delete", value: "Delete"},
                {type: "add", value: t(t_prefix+'col_def_key_value_add_btn')}
            ]
        }
    ];


    const handleFileChange = (files) => {
        setFile(files)
    }

    return (
            <div className="deferred-signature-main">
                <div className="file-wrapper">
                  <div className="file-inner-wrapper">
                    <FileUploadDropzone handleChange={handleFileChange} title={uploadFileText.mandatory} />
                  </div>
                </div>
                <div className="title">
                    {t(t_prefix+'table_deferred_sign_title')}
                </div>

                <AntdTable columns={columns} data={data} />

                <div className="sign-btns-container" >
                  <Button className="btn btn-secondary-red btn-lg fifty-percent-rounded signature-btns signature-margin-right" >
                      {t(t_prefix+'def_cancel_btn')}
                  </Button>
                  <Button className="btn btn-secondary btn-lg fifty-percent-rounded signature-btns">
                      {t(t_prefix+'def_upload_btn')}
                  </Button>
                </div>

            </div>
    )
}

export default DeferredSignature
