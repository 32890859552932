import './App.css';

import Routes from './routes';
import {AppProvider} from "./contextHook";
import {useEffect} from "react";


function App() {
  
    useEffect(() => {
        window.localStorage.setItem('language', 'French')
    }, [])

  return (
        <AppProvider>
                <Routes />
        </AppProvider>
  );
}

export default App;
