import React, { useState } from 'react';
import FileUploadDropzone from "../../components/FileUpload";
import Button from '@material-ui/core/Button';
import AntdTable from '../../components/Tables/antd-table';
import CancelIcon from "@material-ui/icons/Cancel";
import FileUpload from "../../components/fileUplaodComponent";
import CheckFileError from "../../components/checkFilesError";
import CheckFilesError from "../checkFilesError";
import Typography from "@material-ui/core/Typography";
import Modal from "../../components/modal";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {TesozApi} from "../../api";
import SimpleAlerts from "../../components/alerts";
import SuccessFile from "../../components/successFileComponent";
import {useTranslation} from "react-i18next";

const t_prefix = 'upload_files.'
const t_prefix_docs_checked = "upload_files.docs_checked."
const t_prefix_doc__hash_not_found = "upload_files.error_file_doc_not_found."
const t_prefix_hash_not_match = "upload_files.hash_does_not_match."
const t_prefix_generic_error = "upload_files.generic_error."


const CheckFiles = () => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState([]);
    const [optionalFile, setOptionalFile] = useState([]);
    const [selectedProofIndex, setSelectedProofIndex] = useState(null);
    const [data, setData] = useState([])
    const [fileHash, setFileHash] = useState([])
    const [errorModalOpen, setErrorModalOpen] = useState(false)
    const [successModalOpen, setSuccessModalOpen] = useState(false)
    const [displayError, setDisplayError] = useState(false)
    const [detailedData, setDetailedData] = useState(null)
    const [moreApiCalls, setMoreApiCalls] = useState(false)
    const { t } = useTranslation();

    const api = new TesozApi()

    const uploadFileText = {
        mandatory: t(t_prefix+'choose_file_to_upload'),
        optional: t(t_prefix+'choose__proof_file_to_upload_optional')
    }

    const handleFileAdd = async (addFiles) => {

        //creating hash document objects and updating it
        const newObj = []
        for(let i = 0 ; i < addFiles.length ; i++){
            newObj.push({documentHash: await hashFile(addFiles[i].file)})
        }
        setFileHash([...fileHash, ...newObj])

        //updating files array
        setFile([...file, ...addFiles])

        //updating table data
        const tableData = [...data];

        addFiles.map((f, index) => {
                tableData.push({
                key: data.length + index,
                fileName: f.file.name,
                proof: {proof: null, index: data.length + index},
                certified: {isCertified: null, index: data.length + index},
                actions: data.length + index
            })
        })
        setData(tableData)
        setDisplayError(false)
        setError('')
    }

    const handleFileDelete = (deleted) => {
        setFile(file.filter(f => f !== deleted))
    }

    const handleFileOptionalAdd = (addOptionalFiles) => {
        setOptionalFile([...optionalFile, ...addOptionalFiles])
    }

    const handleFileOptionalDelete = (deleted) => {
        console.log("files delete optional", deleted)
    }

    //optional
    // const handleOptionalFileChange = (files) => {
    //     setOptionalFile(files)
    //
    //     const tableData = [...data].map((el, index) => {
    //         return {...el, proof: files.length > 0 ? files[index].name : null}
    //     })
    //
    //     setData(tableData)
    // }

    const onDeleteRow = (index) => {

        //to update files
        const cFiles = [...file].filter((el, i) => i !== index)
        setFile(cFiles)

        //to update optional file
        const oFiles = [...optionalFile].filter((el, i) => i !== index)
        setOptionalFile(oFiles)

        //to update hash object
        const hash = [...fileHash].filter((el, i) => i !== index)
        setFileHash(hash)

        //to update table
        const tableData = [...data].filter((el, i) => i !== index)
        const indexUpdating = tableData.map((el, index) => {
            return {...el, key: index, actions: index, certified: {...el.certified, index: index}, proof: {...el.proof, index: index}}
        })
        setData(indexUpdating)
        setDisplayError(false)
        setError('')

    }

    const handleProofFileSelect = (e, index) => {
        e.preventDefault()
        if(e.target.files[0] && index !== null){
            //updating selected filename in data object
            const tableData = [...data];
            const proofUpdate = tableData[index].proof
            proofUpdate.proof = e.target.files[0].name
            const certifiedUpdate = tableData[index].certified
            certifiedUpdate.isCertified = null
            setData(tableData)

            //updating hashFile with proof file
            const changingHash = [...fileHash];
            const changingHashAtIndex = {...changingHash[index]}
            changingHashAtIndex.proof = e.target.files[0]
            changingHash[index] = changingHashAtIndex
            setFileHash(changingHash)
            setDisplayError(false)

            setSelectedProofIndex(null)
        }
    }

    const handleClearFileSelected = (index) => {
        if(index !== null){
            //clearing data for proof with null
            const tableData = [...data];
            const proofUpdate = tableData[index]
            const certificateUpdate = tableData[index]
            certificateUpdate.certified = {...certificateUpdate.certified, isCertified: null}
            proofUpdate.proof = {proof: null, index: index}
            setData(tableData)

            //clearing hashFile with proof null
            const changingHash = [...fileHash];
            const changingHashAtIndex = {...changingHash[index]}
            changingHashAtIndex.proof = null
            changingHash[index] = changingHashAtIndex
            setFileHash(changingHash)
            setDisplayError(false)

            setSelectedProofIndex(null)
        }
    }

    //below three methods are used for creating document SHA-256 hash
    function hashFile(file) {
        return readBinaryFile(file)
            .then(function(result) {
                result=new Uint8Array(result);
                return window.crypto.subtle.digest('SHA-256', result);
            }).then(function(result) {
            result=new Uint8Array(result);
            return Uint8ArrayToHexString(result);
        });
    }

    function readBinaryFile(file){
        return new Promise((resolve, reject) => {
            let fr = new FileReader();
            fr.onload = () => {
                resolve(fr.result )
            };
            fr.readAsArrayBuffer(file);
        });
    }

    function Uint8ArrayToHexString(ui8array) {
        let hexString='', h;
        for(let i=0; i<ui8array.length; i++) {
            h=ui8array[i].toString(16);
            if(h.length==1) { h='0'+h; }
            hexString+=h;
        }
        let p=Math.pow(2, Math.ceil(Math.log2(hexString.length)));
        hexString=hexString.padStart(p, '0');
        return hexString;
    }

    //modal dialogue content
    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    //error modal methods
    const handleErrorModalOpen = (index) => {
        const tableData = [...data]
        console.log("index is ", index)
        setDetailedData(tableData[index])
        setErrorModalOpen(true)
    }

    const handleErrorModalClose = () => {
        setErrorModalOpen(false)
    }

    //success modal methods
    const handleSuccessModalOpen = (index) => {
        const tableData = [...data]
        console.log("index is ", index)
        setDetailedData(tableData[index])
        setSuccessModalOpen(true)
    }

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false)
    }

    const handleCheckFiles = async() => {
        setLoading(true)
        setError(false)
            const customFormData = []
            for(let i = 0; i < data.length; i++){

                let formData = new FormData();
                formData.append('documentHash', fileHash[i].documentHash);

                if(fileHash[i].proof){
                    formData.append('proof', fileHash[i].proof);
                }

                for (let pair of formData.entries()) {
                    console.log(`key ${i} : `, pair[0], `&& value ${i} : `, pair[1],);
                }

                customFormData.push(formData)
            }

            const allPromise = Promise.all(customFormData.map(myData => api.Check(myData))).catch(e => {
                console.log('A promise failed to resolve', e.response.data.message)
                if(e && e.response && e.response.data && e.response.data.message){
                    setError(e.response.data.message)
                    setLoading(false)
                    return
                }
                else
                {
                    setError('Sorry! Something went wrong...')
                    setLoading(false)
                    return
                }
            })

            const allResponses = await allPromise;
            const newDataSet = []

            if(allResponses){
                for(let index = 0; index < allResponses.length; index++){
                    if(allResponses[index]){
                        if(allResponses[index].status === 200 && allResponses[index].data) {
                            if(allResponses[index].data.output === "OK"){
                                // const {error, hash, output, proof_file_hash} = allResponses[index].data
                                console.log("Success, File is available")
                                const tableData = [...data];
                                const concernedIndex = tableData[index];
                                const updatedObject = {...concernedIndex, certified: {...concernedIndex.certified, isCertified: true}, proof: {...concernedIndex.proof, success: {...allResponses[index].data}}}
                                console.log("updatedObject", updatedObject)
                                newDataSet.push(updatedObject)
                            }else if(allResponses[index].data.output === "KO"){
                                   if (allResponses[index].data.error) {
                                    if (allResponses[index].data.error === "HASH_NOT_PRESENT") {
                                        const {error, hash_document, output} = allResponses[index].data
                                        console.log("HASH_NOT_PRESENT")
                                        const tableData = [...data];
                                        const concernedIndex = tableData[index];
                                        const updatedObject = {
                                            ...concernedIndex,
                                            certified: {...concernedIndex.certified, isCertified: false},
                                            proof: {
                                                ...concernedIndex.proof,
                                                success: null,
                                                inCorrectHash: null,
                                                hashNotPresent: {...allResponses[index].data}
                                            }
                                        }
                                        console.log("updatedObject", updatedObject)
                                        newDataSet.push(updatedObject)
                                    }
                                    else if (allResponses[index].data.error === "INCORRECT_HASH") {
                                        const {error, hash, output, proof_file_hash} = allResponses[index].data
                                        console.log("INCORRECT_HASH")
                                        const tableData = [...data];
                                        const concernedIndex = tableData[index];
                                        const updatedObject = {
                                            ...concernedIndex,
                                            certified: {...concernedIndex.certified, isCertified: false},
                                            proof: {
                                                ...concernedIndex.proof,
                                                success: null,
                                                hashNotPresent: null,
                                                inCorrectHash: {...allResponses[index].data}
                                            }
                                        }
                                        console.log("updatedObject", updatedObject)
                                        newDataSet.push(updatedObject)
                                    }else {
                                        const {error, hash, output, proof_file_hash} = allResponses[index].data
                                        console.log("INCORRECT_HASH")
                                        const tableData = [...data];
                                        const concernedIndex = tableData[index];
                                        const updatedObject = {
                                            ...concernedIndex,
                                            certified: {...concernedIndex.certified, isCertified: false},
                                            proof: {
                                                ...concernedIndex.proof,
                                                success: null,
                                                hashNotPresent: null,
                                                inCorrectHash: null,
                                                genericError: {error: "INCORRECT_TRANSACTION"}
                                            }
                                        }
                                        console.log("updatedObject", updatedObject)
                                        newDataSet.push(updatedObject)
                                    }
                                }
                            }
                        }
                    }
                }
                setData(newDataSet)
                setDisplayError(true)
                setLoading(false)
                setMoreApiCalls(allResponses.length > 1 ? true : false)
            }
            else {
                setLoading(false)
            }
    }

    // const handleRequests = async (cData) => {
    //     const results = [];
    //     for (let i = 0; i < data.length; i++) {
    //         let r = await api.Check(cData);
    //         results.push(r);
    //     }
    //     return results;
    // }

    const columns = [
        {
          title: t(t_prefix+'tbl_col_file_name'),
          dataIndex: 'fileName',
          key: 'fileName',
          render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
          title: t(t_prefix+'tbl_col_proof'),
          dataIndex: 'proof',
          key: 'proof',
          render: ({proof, index}) =>
              <>
                  {console.log("proof : index aaa : ", proof, ":", index)}
                  {proof ?
                      <label style={{color: "#707070", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>{proof}<CancelIcon style={{marginLeft: "4px"}} className="cancel-icon" onClick={() => handleClearFileSelected(index)} /></label>
                      :
                    <label style={{color: "#707070"}}>
                        <FileUpload value={proof} onChange={(e) => handleProofFileSelect(e, selectedProofIndex)} onClick={() => setSelectedProofIndex(index)} />
                    </label>
                  }
              </>
        },
        {
          title: t(t_prefix+'tbl_col_certified'),
          dataIndex: 'certified',
          key: 'certified',
          render: ({isCertified, index}) => {
              console.log("isCertified", isCertified)
              console.log("ceritfied index",  index)
              return (
                  <div style={{display: "flex", flexDirection:"row", justifyContent: "center", textAlign: "center"}}>
                      {isCertified === true && <img alt="proof" className="image-hover" src={window.location.origin + '/img/doc-proof-check-circle.svg'} onClick={() => handleSuccessModalOpen(index)} />}
                      {isCertified === false && <CancelIcon className="cancel-icon image-hover" style={{color: "red"}} onClick={() => handleErrorModalOpen(index)}/>}
                      {isCertified === null && <div style={{width: "19px", height: "20px", borderRadius: "50%", border: "1px solid #707070"}}></div>}

                  </div>
              )
          }

        },
        {
          title: '',
          dataIndex: 'actions',
          key: 'actions',
          render: actions => <img className="image-hover" alt="bin" src={window.location.origin + '/img/bin.svg'} onClick={() => onDeleteRow(actions)} />
        }
    ];

    const errorData = [
        {
            title: t(t_prefix_doc__hash_not_found+'title'),
            detail: [{data: t(t_prefix_doc__hash_not_found+'detail')}],
            icon: true,
            successIcon: false,
        },
        {
            title: t(t_prefix_hash_not_match+'title'),
            detail: [{data: "We are sorry, your proof file does not correspond to the document you want to check. Indeed, the hash of your document " +
                    "(hash) is not the hash of the proof file (Proof File Hash)."}],
            icon: true,
            successIcon: false,
        },
        {
            title: t(t_prefix_docs_checked+'title'),
            detail: [{data: t(t_prefix_docs_checked+'detail')}],
            icon: false,
            successIcon: true,
        },
        {
            title: "Your file is certified.",
            detail: [{data: "The documents you provided have been checked, you can see the results in the table above. If you need more detail about a specific file, click on the result icon."}],
            icon: false,
            successIcon: true,
        },
        {
            title: t(t_prefix_generic_error+'title'),
            detail: [{data: t(t_prefix_generic_error+'detail')}],
            icon: true,
            successIcon: false,
        },
    ]


    return (
        <>

            <FileUploadDropzone title={uploadFileText.mandatory} fileObjects={file}  onAdd={handleFileAdd} onDelete={handleFileDelete} />

            {error && error.length > 0 && <div style={{height: "10vh", marginTop: "30px"}}><SimpleAlerts type="error" message={error} /></div>}

            <div style={{marginTop: "20px"}} >
                {data && data.length > 0 && <AntdTable  columns={columns} data={data} loading={loading} />}
            </div>


            {displayError &&
                <>
                    {moreApiCalls && data.length > 0 ? <CheckFilesError detailData={errorData[2]} /> :
                        <>
                            {data.length === 1 && data[0]  && data[0].proof && data[0].proof.hashNotPresent && <CheckFilesError />}
                            {data.length === 1 && data[0]  && data[0].proof && data[0].proof.inCorrectHash && <CheckFilesError
                                detailData={{
                                    title: t(t_prefix_hash_not_match+'title'),
                                    detail: [{data: t(t_prefix_hash_not_match+'detail_1') +
                                            `(${data[0] && data[0].proof && data[0].proof.inCorrectHash && data[0].proof.inCorrectHash.hash}) ${t(t_prefix_hash_not_match+'detail_2')} (${data[0] && data[0].proof && data[0].proof.inCorrectHash && data[0].proof.inCorrectHash.proof_file_hash}).`}],
                                    icon: true,
                                    successIcon: false,
                                }}
                            />}

                            {data.length === 1 && data[0]  && data[0].proof && data[0].proof.genericError && <CheckFilesError
                                detailData={{
                                    title: "Sorry! Something went wrong.",
                                    detail: [{data: `${data[0]  && data[0].proof && data[0].proof.genericError && data[0].proof.genericError.error}`}],
                                    icon: true,
                                    successIcon: false,
                                }}
                            />}

                            {data.length === 1 && data[0] && data[0].proof && data[0].proof.success  && <>
                                <SuccessFile details={data[0].proof.success} />
                                {/*<CheckFilesError firstElementNone={true} />*/}
                            </>}
                        </>
                    }
                </>
            }


            {/* <FileUploadDropzone title={uploadFileText.optional} disabled={data.length < 1 || optionalFile.length === file.length} fileObjects={optionalFile}  onAdd={handleFileOptionalAdd} onDelete={handleFileOptionalDelete} /> */}

            <Button style={{marginTop: "20px", marginBottom: "40px"}} className="btn btn-primary btn-md contct-us-rounded" fullWidth type="submit" disabled={data.length < 1} onClick={handleCheckFiles} >
                {t(t_prefix+'check_files_btn')}
            </Button>


            {/*ErrorModal*/}
            <Modal open={errorModalOpen} handleOpen={handleErrorModalOpen} handleClose={handleErrorModalClose} >
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {detailedData && detailedData.proof && detailedData.proof.hashNotPresent && <CheckFileError title={errorData[0].title} detail={errorData[0].detail} icon={errorData[0].icon} successIcon={errorData[0].successIcon} />}

                        {detailedData && detailedData.proof && detailedData.proof.inCorrectHash && <CheckFileError title={errorData[1].title}
                                                                                                                   detail={[{data: t(t_prefix_hash_not_match+'detail_1') +
                                `(${detailedData && detailedData.proof && detailedData.proof.inCorrectHash && detailedData.proof.inCorrectHash.hash}) ${t(t_prefix_hash_not_match+'detail_2')} (${detailedData && detailedData.proof && detailedData.proof.inCorrectHash && detailedData.proof.inCorrectHash.proof_file_hash}).`}]}
                                                                                                                   icon={errorData[1].icon} successIcon={errorData[1].successIcon} />}

                        {detailedData && detailedData.proof && detailedData.proof.genericError && <CheckFileError title={errorData[4].title}
                                                                                                                   detail={[ {data: `${detailedData && detailedData.proof && detailedData.proof.genericError && detailedData.proof.genericError.error}`} ]}
                                                                                                                   icon={errorData[4].icon} successIcon={errorData[4].successIcon} />}
                    </Typography>
                </DialogContent>
            </Modal>

            {/*Success Modal*/}
            <Modal open={successModalOpen} handleOpen={handleSuccessModalOpen} handleClose={handleSuccessModalClose} >
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {detailedData && detailedData.proof && detailedData.proof.success && <SuccessFile details={detailedData.proof.success} />}
                    </Typography>
                </DialogContent>
            </Modal>
        </>
    )
}

export default CheckFiles
