import React, {useState, useReducer} from 'react';
import {AppReducer} from "./appReducer";

const AppContext = React.createContext({});
const AppProvider = ({children}) => {
    const [userState, setUserState] = useState(null);

    const initialState = {
        currentUserData: {}
    }

    const [state, dispatch] = useReducer(AppReducer, initialState);

    const setUserData = (data) => {
        dispatch({type: 'SET_USER_DATA', payload: data})
    }

    const setUserLogout = () => {
        dispatch({type: "LOGOUT"})
    }

    return (
        <AppContext.Provider value={{setUserData,  setUserLogout, ...state}}>
            {children}
        </AppContext.Provider>
    )
}

export {AppContext, AppProvider}
