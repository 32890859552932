import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import { TableDateTimeField, Actions } from "./formatter";
import AntdTable from "../../components/Tables/antd-table";
import {TesozApi} from "../../api";
import CustomToolTip from "../../components/toolTip";
import {Space} from "antd";
import DatePicker from "react-datepicker";
import moment from "moment";
import Loader from "../../components/loader";
import {useTranslation} from "react-i18next";
import "./index.scss";

const t_prefix = "channel_management."

const ChannelManagementContent = () => {
    const [loading, setLoading] = useState(false)
    const [createLoading, setCreateLoading] = useState(false)
    const [channelManagement, setChannelManagement] = useState([])
    const [name, setName] = useState("")
    const [expDate, setExpDate] = useState(null);
    const {t} = useTranslation()
    const api = new TesozApi()

    useEffect(() => {
      getAllTokens()
    },[])

    const getAllTokens = () => {
        setLoading(true)
        api.Tokens().then(response => {
            console.log("response", response)
            setLoading(false)
            if(response){
                if(response.status === 200 && response.data){
                    const {data} = response;
                    if(data.length > 0){
                        const cManagementObj = [...response.data].map(el => {

                            const {id, accountId, name, creationDate, expirationDate, jwtToken, revoked} = el

                            return {
                                  key: id,
                                  name: {name, jwtToken, revoked},
                                  creationDate: {creationDate, revoked},
                                  expirationDate: {expirationDate, revoked},
                                  token: jwtToken,
                                  actions: revoked,
                                  accountId
                            }
                        })
                        setChannelManagement(cManagementObj)
                    }
                    else setChannelManagement([])
                }
            }
          }).catch(e => {
                console.log("e", e)
                setLoading(false)

        })
    }


    const columns = [
        {
          title: t(t_prefix+'col_name'),
          dataIndex: 'name',
          key: 'name',
          render: ({name, jwtToken, revoked}) => <Space size="middle" style={{display: "flex", justifyContent: "space-between", color: !revoked ? "#707070" : "#B8DFD8"}}>{name}{!revoked && <CustomToolTip type="Token" id={jwtToken} />}</Space>,
          // render: text => <a>{text}</a>,
        },
        {
          title: t(t_prefix+'col_cr_date'),
          dataIndex: 'creationDate',
          key: 'creationDate',
          render: ({creationDate, revoked}) => <TableDateTimeField dateTime={creationDate} revoked={revoked} />
        },
        {
          title: t(t_prefix+'col_exp_date'),
          dataIndex: 'expirationDate',
          key: 'expirationDate',
          render: ({expirationDate, revoked}) => <TableDateTimeField dateTime={expirationDate} revoked={revoked} />
        },
        // {
        //   title: 'Token',
        //   dataIndex: 'token',
        //   key: 'token',
        //   render: text => <label style={{color: "#707070"}}>{text}</label>,
        //
        // },
        {
          title: '',
          key: 'actions',
          render: actions => <Actions revoked={actions} />
        },
    ];

    const data = [
        {
          key: '1',
          name: 'John Brown',
          creationDate: [{date: "7/27/2021"}],
          expirationDate: [{date: "7/27/2021"}],
          token: 'eyJ0eXiOibgjvhy',
          actions: ''
        },
        {
          key: '2',
          name: 'John Brown',
          creationDate: [{date: "7/27/2021"}],
          expirationDate: [{date: "7/27/2021"}],
          token: 'eyJ0eXiOibgjvhy',
          actions: ''
        },
        {
          key: '3',
          name: 'John Brown',
          creationDate: [{date: "7/27/2021"}],
          expirationDate: [{date: "7/27/2021"}],
          token: 'eyJ0eXiOibgjvhy',
          actions: ''
        }
    ];

    const handleDateChange = (date) => {
        setExpDate(date)
    }

    const handleCreate = () => {
        handleCreateNewToken({name: name, expirationDate: moment(expDate).format("YYYY-MM-DD")+'T'+'00:00:00.000Z'})
    }

    const handleCreateNewToken = (data) => {
        setCreateLoading(true)
        if(data){
            api.CreateNewToken(data).then(response => {
                setCreateLoading(false)
                if(response){
                    if(response.data && response.status === 200){
                        setName('')
                        setExpDate(null)
                        getAllTokens()
                    }
                }
            }).catch(e => {
                console.log("e", e)
                setCreateLoading(false)
            })
        }
    }

    return (
        <div className="channel-management">
            <div className="title">New Token</div>
            <div className="channel-btns-container">
                <input type="text" placeholder={t(t_prefix+'name_placeholder')} className="input-custom-generic input-left-bordered jobs-br-left by-name" value={name} onChange={(e) => setName(e.target.value)}/>
              {/*<Button className="btn btn-outline-primary jobs-br-left by-name btns-font-size">Name</Button>*/}

                <div className="by-name" style={{display: "flex"}}>
                    <DatePicker
                        className="input-custom-generic input-right-bordered by-expiration-date"
                        selected={expDate}
                        onChange={handleDateChange}
                        placeholderText={t(t_prefix+'exp_date_placeholder')}
                        dateFromat='YYYY-MM-DD'
                    />
                </div>
              {/*  <div className="btn-date-picker-container">*/}
              {/*  <Button className="btn btn-outline-primary jobs-br-right by-expiration-date btns-font-size" style={{position: "relative"}} onClick={handleOpenCloseDatePicker}>*/}
              {/*    Expiration Date*/}
              {/*  </Button>*/}
              {/*  /!*<div className="date-container-tesoz">*!/*/}
              {/*  /!*  <ReactDatePicker open={open} onChange={onSelectDate} />*!/*/}
              {/*  /!*</div>*!/*/}
              {/*</div>*/}

              <Button className="btn btn-primary contct-us-rounded by-create btns-font-size" disabled={!name || !expDate ||createLoading} onClick={handleCreate}>{createLoading ? <Loader color="white" /> : t(t_prefix+'create_btn')}</Button>
            </div>
            <div className="title">{t(t_prefix+'table_title')}</div>
            <AntdTable columns={columns} data={channelManagement} loading={loading} />
        </div>
    )
}

export default ChannelManagementContent
