import React from 'react'
import './index.scss'

const JobStatsContainer = ({title, value, qa}) => {
    return (
        <div className={`${qa ? "qa-container" : "stats-container "}`}>
            <div className={`title ${qa ? "" : "flex-clm"}`}>{title}</div>
            <div className={`description ${qa ? "" : "flex-clm"}`}>{value}</div>
        </div>
    )
}

export default JobStatsContainer;