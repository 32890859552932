import React, { useEffect, useState} from 'react';
import JobStatsContainer from '../../components/jobStats'
import AntdTable from "../../components/Tables/antd-table";
import { TableDateTimeField, Actions } from "./formatter"
import {getItem} from "../../utilities/localStorage";
import {TesozApi} from "../../api";
import { useHistory } from "react-router-dom";
import "./index.scss";
import Loader from "../../components/spinner";
import moment from 'moment';
import {useTranslation} from "react-i18next";

const t_prefix  = "dashboard."

const DashboardContent = () => {
    const [jobs, setJobs] = useState([])
    const [jobStats, setJobStats] = useState([])
    const [tableLoading, setTableLoading] = useState(false)
    const [jobStatsLoading, setJobStatsLoading] = useState(false)
    const token = getItem('token');
    const history = useHistory()
    const {t} = useTranslation()
    const api = new TesozApi()


    useEffect(() =>{
        if(token){
            dashboardJobStats()

            whoAmI()
        }
    }, [])

    const dashboardJobStats = () => {

        setJobStatsLoading(true)

        api.Dashboard().then(response => {
            setJobStatsLoading(false)
            if(response){
                const {status, data} = response;
                if(status === 200){
                    if(data){

                        const {jobsCreatedCount, jobsProcessedCount, documentsSignedCount} = data;

                        const jobStatsObj = [
                            {title: t(t_prefix+'job_created_title'), value: jobsCreatedCount},
                            {title: t(t_prefix+'documents_title'), value: documentsSignedCount},
                            {title: t(t_prefix+'job_processed_title'), value: jobsProcessedCount}
                        ]
                        setJobStats(jobStatsObj)
                    }
                }
            }
        }).catch(e => {
            console.log("error dashboard", e)
            setJobStatsLoading(false)
        })
    }

    const whoAmI = () => {
        setTableLoading(true)
        api.Me().then(response => {
            if(response){
                if(response.status === 200){
                    const { data } = response;
                    if(data && data.id){
                        const { id } = data;
                        dashboardJobs(id)
                    }
                }
            }
        }).catch(e => {
            console.log("error", e)
            setTableLoading(false)
        })
    }

    const dashboardJobs = (accountId) => {
        const params = {
            accountId: accountId,
            sort: "createdDate:desc"
        }
        api.Jobs(params).then(response => {
            console.log("response", response)
            setTableLoading(false)
            if(response){
                if(response.status === 200){
                    const {data} = response
                    if(data){
                        let customJobsObj = data.map(obj => {
                            const {flowName, id, createdDate, stateDate, state, channelName, docsNumber} = obj;
                            // var date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                            //
                            // console.log(date); // 2015-09-13 03:39:27

                            // let stillUtcCreatedDate = moment.utc(createdDate).toDate();
                            // let localCreatedDate = moment(createdDate).local().format('YYYY-MM-DD HH:mm:ss');

                            return {
                                key: id,
                                name: flowName,
                                creationDate: moment(createdDate).format("DD-MM-YYYY h:mm:ss A"),
                                statusDate: moment(stateDate).format("DD-MM-YYYY h:mm:ss A"),
                                status: state,
                                docs: docsNumber,
                                channel: channelName || '',
                                actions: id
                            }
                        })

                        console.log("customJobsObj", customJobsObj)
                        setJobs(customJobsObj)
                        // setJobs(data)

                    }
                }

            }
        }).catch(e => {
            setTableLoading(false)
            console.log("error dashboard", e)
        })
    }

    const columns = [
        {
          title: t(t_prefix+'col_name'),
          dataIndex: 'name',
          key: 'name',
          render: text => <label style={{color: "#707070"}}>{text}</label>,
          // render: text => <a>{text}</a>,
        },
        {
          title: t(t_prefix+'col_cr_date'),
          dataIndex: 'creationDate',
          key: 'creationDate',
          render: dateTime => <TableDateTimeField dateTime={dateTime} />
        },
        {
          title: t(t_prefix+'col_st_date'),
          dataIndex: 'statusDate',
          key: 'statusDate',
          render: dateTime => <TableDateTimeField dateTime={dateTime} />
        },
        {
          title: t(t_prefix+'col_status'),
          key: 'status',
          dataIndex: 'status',
          render: text => <label style={{color: "#707070"}}>{text}</label>
        },
        {
          title: t(t_prefix+'col_docs'),
          dataIndex: 'docs',
          key: 'docs',
          render: text => <label style={{color: "#707070"}}>{text}</label>,

        },
        {
          title: t(t_prefix+'col_Channel'),
          dataIndex: 'channel',
          key: 'channel',
            render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
          title: '',
          key: 'actions',
          render: actions => <Actions actions={actions} handleJobDetail={handleJobDetail}/>
        },
    ];

    const handleJobDetail = (id) => {
        console.log("id job detail", id)
        history.push(`/job/detail/${id}`)
    }

    return (
        <div>
            <p className="dashboard-title-desc mt-0" >{t(t_prefix+'title')}</p>
            <p className="dashboard-title-desc mt-0">{t(t_prefix+'sub_title')}</p>

            <h2 className="table-title" style={{marginTop: "13px"}}>{t(t_prefix+'stats_title')}</h2>
            <div className="job-stats-container" >
                {tableLoading ?
                    <div style={{textAlign: "center", width: "100%"}}>
                        <Loader />
                    </div>
                    :
                    <>
                        {jobStats && jobStats.length > 0 && jobStats.map((el, i) => {
                            const {title, value} = el;
                            return (
                                <JobStatsContainer key={i} title={title} value={value} />
                            )
                        })}
                    </>
                }
            </div>

            <h2 className="table-title">{t(t_prefix + 'table_title')}</h2>
            <AntdTable columns={columns} data={jobs} loading={tableLoading} />
        </div>
    )
}

export default DashboardContent
