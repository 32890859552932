import React from "react";
import "./index.scss";

const CustomCheckBox = ({onClick, checked}) => {

    console.log("is checked", checked)
    console.log("onClick", onClick)

    return (
        <div style={{marginTop: "3px"}}>
            {checked ?
                <img alt="checked" src={window.location.origin + '/img/checkBoxTick.svg'} className="custom-check-box-tick_image" onClick={onClick} />
                :
                <div className="custom-check-box" onClick={onClick} />
            }
        </div>
    )
}

export default CustomCheckBox;
