import React from 'react'
import Header from '../header'
import Sidebar from '../sidebar'
import "./index.scss"
import { useLocation } from "react-router-dom";

const Layout = ({children, breadcrumb}) => {
    const location = useLocation()
    const home = "/home";
    let displayMessageIcon = false;
    let displayBackgroundImage = true;
    const background = window.location.origin + '/img/dotted-background.svg';

    if(location){
        if(location.pathname === home){
            displayMessageIcon = true;
            displayBackgroundImage = false;
        }
    }


    return(
        <div className="layout">
            {displayMessageIcon && <div className="message-icon_container">
                <img alt="msg" src={window.location.origin + '/img/messageIcon.svg'} className="message-icon" />
            </div>}
            <Sidebar />
            <div className="header-and-content-container">
                <Header />
                {/* <div className="content-container"></div> */}

                {breadcrumb && <div className="bread-crumb">{breadcrumb}</div>}

                <div className="content-container" style={{backgroundRepeat: "no-repeat", backgroundImage: displayBackgroundImage ? `url(${background})` : ''}}>
                    {children}
                </div>

            </div>
        </div>
    )
}

export default Layout
