import React from 'react';
import Button from '@material-ui/core/Button';
import { Formik, Form, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import FullWidthGrid from '../../components/grid';
import { editMyAccount } from "../../utilities/validations";
import { countriesList } from "../../utilities/constants";
import "./index.scss";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";

const t_prefix = "my_account."

const MyAccountContent = () => {
    const {t} = useTranslation()

    const editMyAccount = Yup.object().shape({
        firstName: Yup.string()
            .required(t(t_prefix+'required'))
            .min(2, t(t_prefix+'first_name_length_short'))
            .max(50, t(t_prefix+'first_name_length_long')),
        lastName: Yup.string()
            .required(t(t_prefix+'required'))
            .min(2, t(t_prefix+'last_name_length_short'))
            .max(50, t(t_prefix+'last_name_length_long')),
        login: Yup.string()
            .required(t(t_prefix+'required'))
            .min(2, t(t_prefix+'login_length_short'))
            .max(50, t(t_prefix+'login_length_long')),
        email: Yup.string().email(t(t_prefix+'invalid_email')).required(t(t_prefix+'required')),
        // company: Yup.string()
        //     .required(t(t_prefix+'required'))
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        // country: Yup.string()
        //     .required(t(t_prefix+'required'))
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        // profile: Yup.string()
        //     .required(t(t_prefix+'required'))
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        signatureLimit: Yup.string()
            .required(t(t_prefix+'required'))
            .min(2, t(t_prefix+'sign_limit_length_short'))
            .max(50, t(t_prefix+'sign_limit_length_long')),
        // tesozPublicKey: Yup.string()
        //     .required(t(t_prefix+'required'))
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        // tesozAccount: Yup.string()
        //     .required(t(t_prefix+'required'))
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!'),
        password: Yup.string().required(t(t_prefix+'required')),
        passwordConfirmation: Yup.string().required(t(t_prefix+'required')).oneOf([Yup.ref('password'), null], t(t_prefix+'passwords_must_match'))
    });

    const getModifiedValues = (values, initialValues) => {
        let modifiedValues = {};

        if (values) {
            Object.entries(values).forEach((entry) => {
                let key = entry[0];
                let value = entry[1];

                if (value !== initialValues[key]) {
                    modifiedValues[key] = value;
                }
            });
        }

        return handleSubmit(modifiedValues);
    };

    const handleSubmit = (values) => {
        console.log("values edit profile", values)
    }

    const initialValues = {
        firstName: 'aaaa',
        lastName: 'aaaa',
        login: 'admin',
        email: 'zaidchaudhary056@gmail.com',
        company: 'hexa',
        country: 'PK',
        profile: 'aaaaaaa',
        signatureLimit: 'aaaaaa',
        tesozPublicKey: 'aaaaaa',
        tesozAccount: 'aaaaaaaaa',
        password: '12345',
        passwordConfirmation: '12345'
    }

    return (
        <div className="my-account-edit">
            <div className="title">
                {t(t_prefix+'title')}
            </div>
            <div>
            <Formik
                        initialValues={initialValues}

                        validationSchema={editMyAccount}

                        onSubmit={(values) => getModifiedValues(values, initialValues)}
                    >
                        {({ errors, touched }) => (
                            <Form className="edit-account-form">
                                <FullWidthGrid>
                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'first_name_label')}</label>
                                        <Field name="firstName" placeholder={t(t_prefix+'first_name_placeholder')} className={`edit-acc-text-field ${(!errors.firstName || !touched.firstName) ? 'edit-margin-btm' : ''}`} />
                                        {errors.firstName && touched.firstName ? (
                                            <div className="mgn-btm">{errors.firstName}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'last_name_label')}</label>
                                        <Field name="lastName" placeholder={t(t_prefix+'last_name_placeholder')} className={`edit-acc-text-field ${(!errors.lastName || !touched.lastName) ? 'edit-margin-btm' : ''}`} />
                                        {errors.lastName && touched.lastName ? (
                                            <div className="mgn-btm">{errors.lastName}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'login_label')}</label>
                                        <Field name="login" placeholder={t(t_prefix+'login_placeholder')} className={`edit-acc-text-field ${(!errors.login || !touched.login) ? 'edit-margin-btm' : ''}`} />
                                        {errors.login && touched.login ? (
                                            <div className="mgn-btm">{errors.login}</div>
                                        ) : null}
                                    </Grid>


                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'email_label')}</label>
                                        <Field name="email" type="email" placeholder={t(t_prefix+'email_placeholder')} className={`edit-acc-text-field ${(!errors.email || !touched.email) ? 'edit-margin-btm' : ''}`} />
                                        {errors.email && touched.email ? <div className="mgn-btm">{errors.email}</div> : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'country_label')}</label>
                                        <Field name="company" placeholder={t(t_prefix+'country_placeholder')} className={`edit-acc-text-field ${(!errors.company || !touched.company) ? 'edit-margin-btm' : ''}`} />
                                        {errors.company && touched.company ? (
                                            <div className="mgn-btm">{errors.company}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'country_label')}</label>
                                        <Field name="country" as="select" placeholder={t(t_prefix+'country_placeholder')} className={`select-field edit-acc-text-field ${(!errors.country || !touched.country) ? 'edit-margin-btm' : ''}`} >
                                            {countriesList.map(country => {
                                                const {name, code} = country;
                                                return (
                                                    <option value={code}>{name}</option>
                                                )
                                            })}
                                        </Field>
                                        {errors.country && touched.country ? (
                                            <div className="mgn-btm">{errors.country}</div>
                                        ) : null}
                                    </Grid>

                                    {/* <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">Country</label>
                                        <Field name="country" placeholder="Seelct your Country" className={`edit-acc-text-field ${(!errors.country || !touched.country) ? 'edit-margin-btm' : ''}`} />
                                        {errors.country && touched.country ? (
                                            <div className="mgn-btm">{errors.country}</div>
                                        ) : null}
                                    </Grid> */}

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'profile_label')}</label>
                                        <Field name="profile" placeholder={t(t_prefix+'profile_placeholder')} className={`edit-acc-text-field ${(!errors.profile || !touched.profile) ? 'edit-margin-btm' : ''}`} />
                                        {errors.profile && touched.profile ? (
                                            <div className="mgn-btm">{errors.profile}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'sign_limit_label')}</label>
                                        <Field name="signatureLimit" placeholder={t(t_prefix+'sign_limit_placeholder')} className={`edit-acc-text-field ${(!errors.signatureLimit || !touched.signatureLimit) ? 'edit-margin-btm' : ''}`} />
                                        {errors.signatureLimit && touched.signatureLimit ? (
                                            <div className="mgn-btm">{errors.signatureLimit}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'tesoz_public_key_label')}</label>
                                        <Field name="tesozPublicKey" placeholder={t(t_prefix+'tesoz_public_key_placeholder')} className={`edit-acc-text-field ${(!errors.tesozPublicKey || !touched.tesozPublicKey) ? 'edit-margin-btm' : ''}`} />
                                        {errors.tesozPublicKey && touched.tesozPublicKey ? (
                                            <div className="mgn-btm">{errors.tesozPublicKey}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'tesoz_account_label')}</label>
                                        <Field name="tesozAccount" placeholder={t(t_prefix+'tesoz_account_placeholder')} className={`edit-acc-text-field ${(!errors.tesozAccount || !touched.tesozAccount) ? 'edit-margin-btm' : ''}`} />
                                        {errors.tesozAccount && touched.tesozAccount ? (
                                            <div className="mgn-btm">{errors.tesozAccount}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'change_password_label')}</label>
                                        <Field name="password" type="password" placeholder={t(t_prefix+'change_password_placeholder')} className={`edit-acc-text-field ${(!errors.password || !touched.password) ? 'edit-margin-btm' : ''}`} />
                                        {errors.password && touched.password ? (
                                            <div className="mgn-btm">{errors.password}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'confirm_change_password_label')}</label>
                                        <Field name="passwordConfirmation" type="password" placeholder={t(t_prefix+'confirm_change_password_placeholder')} className={`edit-acc-text-field ${(!errors.passwordConfirmation || !touched.passwordConfirmation) ? 'edit-margin-btm' : ''}`} />
                                        {errors.passwordConfirmation && touched.passwordConfirmation ? (
                                            <div className="mgn-btm">{errors.passwordConfirmation}</div>
                                        ) : null}
                                    </Grid>
                                </FullWidthGrid>


                                <div className="sign-btns-container" >
                                                <Button className="btn btn-secondary-red btn-lg fifty-percent-rounded signature-btns signature-margin-right" >
                                                    {t(t_prefix+'cancel_btn')}
                                                </Button>
                                                <Button className="btn btn-secondary btn-lg fifty-percent-rounded signature-btns" type="submit">
                                                    {t(t_prefix+'save_btn')}
                                                </Button>
                                </div>

                                {/* <div className="btn-margin">
                                    <Button className="btn btn-primary btn-lg contct-us-rounded contct-us-padding contact-us-btn" type="submit">
                                        Send Us a Message
                                    </Button>
                                </div> */}

                            </Form>
                        )}
                    </Formik>
            </div>

        </div>
    )
}

export default MyAccountContent
