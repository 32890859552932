import {removeItem} from "../../utilities/localStorage";

export const AppReducer = (state, action) => {
    const {type, payload} = action
    switch (type){
        case 'SET_USER_DATA':
            return {...state, currentUserData: payload}
        case 'LOGOUT':
            removeItem('token')
            return {...state, currentUserData: {}}
        default:
            return state
    }

}
