import React from 'react';
import HomeLayout from '../components/homeLayout';
import Container from '@material-ui/core/Container';
import HomeMain from "../components/home/homeMain";
import Footer from "../components/home/footer";
import LetsConnect from "../components/home/letsConnect";
import ContactUs from "../components/home/contactUs";
import HomeDetail from "../components/home/homeDetail";
import { useHistory } from "react-router-dom";

const Home = () => {
    const history = useHistory()
    console.log("history")
    return (
        <HomeLayout breadcrumb={null}>
            <div className="home">
                <Container maxWidth="lg">
                    <HomeMain />
                    <HomeDetail />
                </Container>
                <ContactUs />
                <Container maxWidth="lg">
                    <LetsConnect />
                    <Footer />
                </Container>
            </div>
        </HomeLayout>
    )
}

export default Home
