import React, {useContext, useEffect, useState} from 'react';
import { Space } from 'antd';
import Button from '@material-ui/core/Button';
import AntdTable from "../../components/Tables/antd-table";
import { TableDateTimeField, Actions } from "./formatter";
import moment from "moment";
import {TesozApi} from "../../api";
import {getItem} from "../../utilities/localStorage";
import {useHistory} from "react-router-dom";
import CustomToolTip from "../../components/toolTip";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {AppContext} from "../../contextHook";
import {useTranslation} from "react-i18next";
import "./index.scss"

const t_prefix = "jobs."

const JobsContent = () => {
    const [tableLoading, setTableLoading] = useState(false)
    const [jobs, setJobs] = useState([])
    const [name, setName] = useState(null)
    const [id, setId] = useState(null)
    const [startDate, setStartDate] = useState(null);
    const [channelNames, setChannelNames] = useState([])
    const [channelValue, setChannelValue] = useState(null)
    const {setUserLogout} = useContext(AppContext)
    const history = useHistory()
    const {t} = useTranslation();

    const token = getItem('token');
    const api = new TesozApi()

    useEffect(() =>{
        if(token){
            UserTokens()
        }
    }, [])

    const UserTokens = (fname, jobId, selectedDate, cValue) => {
        setTableLoading(true)
        api.Tokens().then(response => {
            if(response){
                if(response.status === 200){
                    const { data } = response;

                    const channels = data.map(el => {
                        const {name} = el;
                        return {
                            name
                        }
                    })

                    // channels.unshift({id: '', name: 'Channel'})

                    setChannelNames(channels)

                    if(data && data.length > 0 && data[0].accountId){
                        const { accountId } = data[0];
                        jobsList(accountId, fname, jobId, selectedDate, cValue)
                    }
                }
            }
        }).catch(e => {
            console.log("error", e)
            if(e){
                if(e.response){
                    if(e.response.data){
                        if(e.response.data.message) {
                            if(e.response.status === 403 && e.response.data.message === "Expired token"){
                                setUserLogout()
                                history.push("/")
                            }
                            else {

                            }
                        }
                    }
                }
            }
            setTableLoading(false)
        })
    }

    const jobsList = (accountId, name=null, jobId=null, selectedDate = null, channel=null) => {
        const params = {
            accountId: accountId,
            sort: "createdDate:desc"
        }

        params.flowName = name
        params.id = jobId
        params.dateBegin = selectedDate
        params.channel = channel

        api.Jobs(params).then(response => {
            console.log("response", response)
            setTableLoading(false)
            if(response){
                if(response.status === 200){
                    const {data} = response
                    if(data){

                        let customJobsObj = data.map(obj => {
                            const {flowName, id, createdDate, stateDate, state, channelName, docsNumber} = obj;
                            return {
                                key: id,
                                name: {flowName, id},
                                creationDate: moment(createdDate).format("DD-MM-YYYY h:mm:ss A"),
                                statusDate: moment(stateDate).format("DD-MM-YYYY h:mm:ss A"),
                                status: state,
                                docs: docsNumber,
                                channel: channelName || '',
                                actions: id
                            }
                        })

                        console.log("customJobsObj", customJobsObj)
                        setJobs(customJobsObj)
                        // setJobs(data)

                    }
                }

            }
        }).catch(e => {
            setTableLoading(false)
            console.log("error job List", e)
            console.log("error job List", e.response)
        })
    }

    const columns = [
        {
          title: t(t_prefix+'col_name'),
          dataIndex: 'name',
          key: 'name',
          render: ({flowName, id}) => <Space size="middle" style={{display: "flex", justifyContent: "space-between", color: "#707070"}}>{flowName} <CustomToolTip type="ID" id={id} /></Space>,
          // render: text => <a>{text}</a>,
        },
        {
          title: t(t_prefix+'col_cr_date'),
          dataIndex: 'creationDate',
          key: 'creationDate',
          render: dateTime => <TableDateTimeField dateTime={dateTime} />
        },
        {
          title: t(t_prefix+'col_st_date'),
          dataIndex: 'statusDate',
          key: 'statusDate',
          render: dateTime => <TableDateTimeField dateTime={dateTime} />
        },
        {
          title: t(t_prefix+'col_status'),
          key: 'status',
          dataIndex: 'status',
          render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
          title: t(t_prefix+'col_docs'),
          dataIndex: 'docs',
          key: 'docs',
          render: text => <label style={{color: "#707070"}}>{text}</label>,

        },
        {
          title: t(t_prefix+'col_channel'),
          dataIndex: 'channel',
          key: 'channel',
            render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
          title: '',
          key: 'actions',
          render: actions => <Actions actions={actions} handleJobDetail={handleJobDetail} handleJobListDetail={handleJobListDetail} handleMerkelTreeDoc={handleMerkelTreeDoc}/>
        },
    ];

    const handleJobDetail = (id) => {
        console.log("id job detail", id)
        history.push(`/job/detail/${id}`)
    }

    const handleJobListDetail = (id) => {
        console.log("id job document list detail", id)
        // history.push(`/documentlist/${id}`)
        history.push({pathname: '/documentlist', state: {relatedJob: id}})
    }

    const handleMerkelTreeDoc = (id) => {
        console.log("id handleMerkelTreeDoc", id)
        setTableLoading(true)
        if(id){
            api.MerkelTree(id).then(response => {
                console.log("response", response)
                setTableLoading(false)
                if(response){
                    if(response.status === 200 && response.data){
                        downLoadMerkelTreeDoc(response.data, id + "_tree")
                    }
                    setTableLoading(false)
                }
            }).catch(e => {
                console.log("e", e)
            })
        }
    }

    const downLoadMerkelTreeDoc = (data, fileName) => {
        setTableLoading(true)
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", fileName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
        setTableLoading(false)
    }

    const handleChannelValue = (e) => {
        setChannelValue(e.target.value)
    }

    const handleDateChange = (date) => {
        setStartDate(date)
    }

    const handleSearch = () => {
        let customDate = startDate ? (moment(startDate).format('YYYY-MM-DD')) : null
        UserTokens(name, id, customDate, channelValue)
    }

    return (
        <div className="jobs-content">
          <div className="btns-container">
            <div className="left">
                <input type="text" placeholder={t(t_prefix + 'input_name_placeholder')} className="input-custom-generic input-left-bordered by-name" onChange={(e) => setName(e.target.value)}/>
                {/*<Button className="btn btn-outline-primary jobs-br-left by-name btns-font-size">Name</Button>*/}

                <input type="text" placeholder={t(t_prefix + 'input_id_placeholder')} className="input-custom-generic input-normal-bordered by-id" onChange={(e) => setId(e.target.value)}/>
                {/*<Button className="btn btn-outline-primary by-id btns-font-size" >ID</Button>*/}

                <div className="by-date" style={{display: "flex"}}>
                    <DatePicker
                        className="input-custom-generic input-normal-bordered input-date-width"
                        selected={startDate}
                        onChange={handleDateChange}
                        placeholderText={t(t_prefix + 'input_cr_date_placeholder')}
                        dateFromat='YYYY-MM-DD'
                    />
                </div>
                {/*<Button className="btn btn-outline-primary by-date btns-font-size">Creation Date</Button>*/}

                <select className="input-custom-generic input-right-bordered by-channel" value={channelValue} onChange={handleChannelValue}>
                    <option value="" disabled selected>{t(t_prefix+'input_channel_placeholder')}</option>
                    {channelNames && channelNames.length > 0 &&
                        channelNames.map(el => <option value={el.name}>{el.name}</option>)
                    }
                </select>
                {/*<Button className="btn btn-outline-primary jobs-br-right by-channel btns-font-size" >Channel</Button>*/}
                <Button className="btn btn-primary contct-us-rounded by-search btns-font-size" onClick={handleSearch}>{t(t_prefix + 'search_btn')}</Button>
            </div>
            <div className="right">
              <Button className="btn btn-primary contct-us-rounded by-export btns-font-size">{t(t_prefix + 'export_btn')}</Button>
            </div>
          </div>
          <AntdTable columns={columns} data={jobs} loading={tableLoading} />
        </div>
    )
}

export default JobsContent
