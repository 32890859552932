export const saveItem = (key, value) => {
    window.sessionStorage.setItem(key, value);
}

export const getItem = (key) => {
    return window.sessionStorage.getItem(key);
}

export const removeItem = (key) => {
    window.sessionStorage.removeItem(key);
}
