import React from 'react';
import Container from '@material-ui/core/Container';
import FullWidthGrid from '../components/grid';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),

});

const ForgotPassword = () => {
    const classes = useStyles();
    const history = useHistory()

    const formik = useFormik({
        initialValues: {
          email: 'foobar@example.com'
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          alert(JSON.stringify(values, null, 2));
        },
    });

    return (
        <div className="signin">
           <Container maxWidth="lg" className="signin-bg-clr" >
                <div className="just-signin-logo">
                    <div>
                        {/*<img src={window.location.origin + '/img/headerLogoSmall.png'} />*/}
                        {/*<img src={window.location.origin + '/img/new-sword-image-lil-dull.png'} className="new-signin-logo" />*/}

                        <img alt="trimmed new logo" src={window.location.origin + '/img/trimmed-new-logo.png'} className="latest-trimmed-logo" />

                    </div>
                </div>
                <FullWidthGrid>
                    <Grid item xs={12} sm={12} md={6} >
                        <div className="image-wrapper">
                            <img alt="forgot password" src={window.location.origin + '/img/forgotpassword.svg'} style={{maxHeight: "500px"}} className="signin-logo-size" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="new-form-grid-padding">
                        <div className="just-signin-form-container">
                            <label className="just-signin-title">
                                SignIn
                            </label>
                            <p className="just-signin-description">
                                Please enter your email address or username to receive the link to reset your password.
                            </p>
                            <form onSubmit={formik.handleSubmit}>
                                <div style={{height: '25vh', display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                    <TextField
                                        style={{marginBottom: "12px"}}
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </div>

                                <div className="forgot-btns">
                                    <div className="fifty-percent-width">
                                        <Button className="btn btn-primary btn-lg new-size-signin-forgt-btns" fullWidth onClick={() => history.push("/")}>
                                            SignIn
                                        </Button>
                                    </div>

                                    <div className="fifty-percent-width">
                                        <Button className="btn btn-primary btn-lg new-size-signin-forgt-btns" fullWidth type="submit">
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </form>
                            <label className="already-account">
                                Already have an account<span className="already-account_signup"> SignUp</span>
                            </label>
                        </div>
                    </Grid>
                </FullWidthGrid>

            </Container>
        </div>
    )
}

export default ForgotPassword
