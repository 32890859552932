import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import FileUploadDropzone from "../../components/FileUpload";
import AntdTable from "../../components/Tables/antd-table";
import CheckBox from "../../components/checkbox";
import CustomCheckBox from "../../components/customCheckBox";
import Button from '@material-ui/core/Button';
import { Formik, Form, Field } from 'formik';
import FullWidthGrid from '../../components/grid';
import { signatureReqSchema } from "../../utilities/validations"
import "./index.scss";
import {useTranslation} from "react-i18next";

const t_prefix = "signature_request."

const DirectSignature = () => {
    const [file, setFile] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    const {t} = useTranslation()

    const uploadFileText = {
        mandatory: t(t_prefix+'dir_choose_file_upload'),
    }

    const columns = [
        {
            title: t(t_prefix+'col_dir_file_name'),
            dataIndex: 'filename',
            key: 'filename',
            render: text => <label style={{color: "#707070"}}>{text}</label>
        },
        {
            title: t(t_prefix+'col_dir_size'),
            dataIndex: 'size',
            key: 'size',
            render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
            title: t(t_prefix+'col_dir_hash'),
            dataIndex: 'hash',
            key: 'hash',
            render: text => <label style={{color: "#707070"}}>{text}</label>,
        },
        {
            title: t(t_prefix+'col_dir_key_value'),
            key: 'keysandvalues',
            dataIndex: 'keysandvalues',
            render: data => (
                <>
                    {data.map(el => {
                        const {type, value} = el;
                        return (
                            <>
                                {type === 'file-batch' && <label className="file-badge">{value}</label>}
                                {type === 'delete' && <img alt="bin" className="delete-badge" src={window.location.origin + '/img/bin.svg'} />}
                                {type === 'add' && <label className="add-badge" >{value}</label>}
                            </>
                        )
                    })}
                </>
            )
        }
    ];

    const data = [
        {
            key: '1',
            filename: 'Arsahd_resume.pdf',
            size: '117KB',
            hash: 'hjhjhhjhjhjh',
            keysandvalues: [
                {type: "file-batch", value: "file_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "delete", value: "Delete"},
                {type: "add", value: t(t_prefix+'col_dir_key_value_add_btn')}
            ]

        },
        {
            key: '2',
            filename: 'Maheen_cv.pdf',
            size: '123KB',
            hash: 'hjyhjyhjyhjy',
            keysandvalues: [
                {type: "file-batch", value: "file_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "delete", value: "Delete"},
                {type: "add", value: t(t_prefix+'col_dir_key_value_add_btn')}
            ]
        },
        {
            key: '3',
            filename: 'John_cv.pdf',
            size: '122KB',
            hash: 'hjhjhhjhjhjhyui',
            keysandvalues: [
                {type: "file-batch", value: "file_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "file-batch", value: "batch_id"},
                {type: "delete", value: "Delete"},
                {type: "add", value: t(t_prefix+'col_dir_key_value_add_btn')}
            ]
        }
    ];


    const handleFileChange = (files) => {
        setFile(files)
    }

    const handleSubmit = (values) => {
      console.log("values new user", values)
    }

    return (
            <div className="direct-signature-main">
                <div className="title">
                    1. {t(t_prefix + 'start_job_title')}
                </div>
                <div className="form-main-container">
                  <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            hashAlgorithm: '',
                            data: '',
                            email: ''
                        }}

                        validationSchema={signatureReqSchema}

                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form className="edit-account-form">

                                <FullWidthGrid>
                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'first_name_label')}</label>
                                        <Field name="firstName" placeholder={t(t_prefix+'first_name_placeholder')} className={`edit-acc-text-field ${(!errors.firstName || !touched.firstName) ? 'edit-margin-btm' : ''}`} />
                                        {errors.firstName && touched.firstName ? (
                                            <div className="mgn-btm">{errors.firstName}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'last_name_label')}</label>
                                        <Field name="lastName" placeholder={t(t_prefix+'last_name_placeholder')} className={`edit-acc-text-field ${(!errors.lastName || !touched.lastName) ? 'edit-margin-btm' : ''}`} />
                                        {errors.lastName && touched.lastName ? (
                                            <div className="mgn-btm">{errors.lastName}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'hash_algo_label')}</label>
                                        <Field name="hashAlgorithm" placeholder={t(t_prefix+'hash_algo_placeholder')} className={`edit-acc-text-field ${(!errors.hashAlgorithm || !touched.hashAlgorithm) ? 'edit-margin-btm' : ''}`} />
                                        {errors.hashAlgorithm && touched.hashAlgorithm ? (
                                            <div className="mgn-btm">{errors.hashAlgorithm}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'batch_id_label')}</label>
                                        <Field name="data" placeholder={t(t_prefix+'batch_id_placeholder')} className={`edit-acc-text-field ${(!errors.data || !touched.data) ? 'edit-margin-btm' : ''}`} />
                                        {errors.data && touched.data ? (
                                            <div className="mgn-btm">{errors.data}</div>
                                        ) : null}
                                    </Grid>

                                    <Grid item sm={12} lg={6}>
                                        <label className="edit-acc-label">{t(t_prefix+'email_label')}</label>
                                        <Field name="email" type="email" placeholder={t(t_prefix+'email_placeholder')} className={`edit-acc-text-field ${(!errors.email || !touched.email) ? 'edit-margin-btm' : ''}`} />
                                        {errors.email && touched.email ? <div className="mgn-btm">{errors.email}</div> : null}
                                    </Grid>
                                </FullWidthGrid>

                                <div className="title title-padding-top">
                                    2. {t(t_prefix+'upload_files_title')}
                                </div>
                                <div className="file-container">
                                  <Grid item sm={12} md={6}>
                                    <FileUploadDropzone handleChange={handleFileChange} title={uploadFileText.mandatory} signatureReq={true} />
                                  </Grid>
                                </div>
                                <div className="title">
                                    {t(t_prefix+'table_direct_sign_title')}
                                </div>
                                <AntdTable columns={columns} data={data} />
                                <div className="recieve-email-checkbox-container">
                                    {/* <CheckBox onClick={() => setIsChecked(!isChecked)} checked={isChecked} /> */}
                                    <CustomCheckBox onClick={() => setIsChecked(!isChecked)} checked={isChecked} /> <div className="rec-email-text">{t(t_prefix+'receive_email_text')}</div>
                                </div>
                                <div className="sign-btns-container" >
                                  <Button className="btn btn-secondary btn-lg fifty-percent-rounded signature-btns signature-margin-right" >
                                      {t(t_prefix+'dir_cancel_btn')}
                                  </Button>
                                  <Button className="btn btn-secondary btn-lg fifty-percent-rounded signature-btns" type="submit">
                                      {t(t_prefix+'dir_save_btn')}
                                  </Button>
                                  {/* do not remove */}
                                  {/* <div className="sign-btns-container" >
                                                <Button className="btn btn-secondary-red btn-lg fifty-percent-rounded signature-btns signature-margin-right" >
                                                                Cancel
                                                </Button>
                                                <Button className="btn btn-secondary btn-lg fifty-percent-rounded signature-btns" type="submit">
                                                                Save
                                                </Button>
                                </div> */}
                              </div>
                            </Form>)}
                  </Formik>
                </div>

            </div>
    )
}

export default DirectSignature
